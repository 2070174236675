import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

export const init = () => {
  Sentry.init({
    dsn: 'https://8ba9728224464ea3a82c17a9a310fa53@o4504911798206464.ingest.sentry.io/4504911810985984',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}
