import { CharacterDef } from './Characters'

export enum CommandName {
  ONLY = '--only',
  EXCLUDE = '--exclude',
  NONARRATOR = '--nonarrator',
  LOWERCASE = '--lowercase',
  NOWRITINGRULES = '--nowritingrules',
  CURRENT = '--current',
  ID = '--id',
}

export const STUDENTS_IDENTIFIER = 'students'
export const TEACHERS_IDENTIFIER = 'teachers'

export interface PromptCommand {
  name: CommandName
  args: string[]
}

export const createCharacterDescriptions = ((characters: CharacterDef[]): string[] => {
  return characters.map(c => `${c.name} (${c.description})`)
})

export const getCharacterDescriptionsPromptPart = ((characters: CharacterDef[], onlyCharacterArgs: string[] | undefined): string[] => {
  const characterLines = [] as string[]
  const characterDescriptions = createCharacterDescriptions(characters)
  if (onlyCharacterArgs && onlyCharacterArgs.length > 0) {
    const onlyCharacterNames = onlyCharacterArgs.join(', ')
    characterLines.push(`In this chapter, ONLY feature the characters ${onlyCharacterNames}`)
  }
  if (characterDescriptions.length > 0) {
    characterLines.push(`Character descriptions:`)
    characterLines.push(...characterDescriptions)
  }
  return characterLines
})

export const getEpisodeCreationPrompt = ((topic: string, characters: CharacterDef[], commands: PromptCommand[], prequelEpisodeScript: string | null): string => {
  const useNarrator = !commands.some(c => c.name === CommandName.NONARRATOR)
  const useLowercaseDialogue = commands.some(c => c.name === CommandName.LOWERCASE)
  const noWritingRules = commands.some(c => c.name === CommandName.NOWRITINGRULES)

  const onlyCharacterArgs = commands.find(c => c.name === CommandName.ONLY)?.args

  if (prequelEpisodeScript) {
    const excludeCharacterArgs = commands.find(c => c.name === CommandName.EXCLUDE)?.args

    const sequelPromptLines = [
      `<instructions>`,
      `Begin with a new title and a location. The new dialog should be 500 words long.`,
      `You must output only the dialog and narration, no additional information.`,
      ...getCharacterDescriptionsPromptPart(characters, onlyCharacterArgs),
    ]
    if (excludeCharacterArgs) {
      sequelPromptLines.push(`Following characters MUST NOT appear in this episode: ${excludeCharacterArgs.join(', ')}.`)
    }
    sequelPromptLines.push(...[
      `<important-instruction>`,
      `${topic && topic !== '' ? topic : `Continue the story in a satisfying way.`}`,
      `</important-instruction>`,
    ],
    )
    if (useLowercaseDialogue) {
      sequelPromptLines.push(`DIALOG_TEXT must have NO punctuation at all, only leave the punctuation like this: \`name (emotion): "dialog_text"\`. DIALOG_TEXT should be written in all lowercase in a dumb and hilarious awkward degen way.`)
    }
    if (!useNarrator) {
      sequelPromptLines.push(`Omit narration lines as much as possible. Make a longer dialogue instead.`)
    }
    sequelPromptLines.push(...[
      `Please continue this dialog after the last line (do NOT repeat this dialog, just continue with new title and location):`,
      `</instructions>`,
      `<prequel>`,
      prequelEpisodeScript,
      `</prequel>`,
    ])
    return sequelPromptLines.join('\n\n')
  }

  const promptLines = [] as string[]

  promptLines.push(...[
    `Create 300 or more words long dialog`,
    topic,
  ])

  // const holidayRandomizer = Math.random()
  // if (holidayRandomizer <= .33) {
  //   promptLines.push(`This chapter takes place during winter, Christmas or 2023 New Year's eve time.`)
  // }

  promptLines.push(...getCharacterDescriptionsPromptPart(characters, onlyCharacterArgs))

  promptLines.push(...[
    `The first line of your output: the title of the chapter, using the format \`TITLE: {TITLE_TEXT}\`.`,
    `The second line of your output: the location the chapter takes place in, using the format \`LOCATION: {LOCATION - TIME OF DAY}\`. New locations are written on a separate line, using the format \`LOCATION: {LOCATION - TIME OF DAY}\`.`,
    `Afterwards, your output will be the dialog.\nDialog must use the format \`NAME (EMOTION): "DIALOG_TEXT"\`. DIALOG_TEXT contains ONLY the words that are said out loud.`,
  ])
  if (useLowercaseDialogue) {
    promptLines.push(`Important instruction: DIALOG_TEXT must have NO punctuation at all, only leave the punctuation as described in the format above. DIALOG_TEXT should be written in all lowercase in a dumb and hilarious awkward degen way.`)
  }
  promptLines.push(`You must output only the dialog and narration, no additional information.`)
  if (!noWritingRules) {
    promptLines.push(`WRITING RULES: Write definitive conclusions, preambles, and epilogues. Write conclusively. Write with moral indifference. Write engaging, fitting dialogue. Write as a realist, highlighting despair and defeat. Write in the present, and detail or elaborate on any time transitions. Any actions performed by the characters should be described in narration on a new line. \`DIALOG_TEXT\` contains ONLY the words that are said out loud.`)
  }
  if (useNarrator) {
    promptLines.push(...[
      `Throughout the dialogue, be the narrator and describe character emotions and inner thoughts. Remember that it's casual school story. Narration must be very short.`,
      `You must be emotionally invested in the story. You always judge or praise characters' actions and statements, making witty remarks. Keep in mind that you have already narrated thousands of chapters, so you sometimes express your annoyance with the job as narrator.`,
    ])
  }
  return promptLines.join('\n\n')
})