import { EpisodesInfoData, QueueInfoData, WsPath } from '../../common/src/Types'
import { EpisodesRow } from './EpisodesRow'
import WsClient from './WsClient'

export class CommunicationWs {
  private onInfoCb: ((info: QueueInfoData) => void) | null = null
  private onEpisodesInfoCb: ((episodesInfo: EpisodesInfoData) => void) | null = null
  private ws: WsClient | null = null
  private timeout = 2000
  private episodesInfoTimeout: NodeJS.Timeout | null = null

  private isRequestingEpisodes = false
  public requestEpisodesOffset: number | null = null
  public requestEpisodesLimit: number | null = null

  public stop() {
    this.stopRequestingEpisodes()
    this.ws?.disconnect()
    this.ws = null
  }

  public onInfo(cb: (info: QueueInfoData) => void) {
    this.onInfoCb = cb
  }

  public onEpisodesInfo(cb: (episodesInfo: EpisodesInfoData) => void) {
    this.onEpisodesInfoCb = cb
  }

  public requestEpisodes() {
    if (!this.ws) return
    if (this.isRequestingEpisodes) return
    this.isRequestingEpisodes = true
    this.ws.send(JSON.stringify({
      event: 'requestEpisodes',
      data: {
        offset: this.requestEpisodesOffset,
        limit: this.requestEpisodesLimit,
      },
    }))
  }

  public stopRequestingEpisodes() {
    if (this.episodesInfoTimeout) {
      clearTimeout(this.episodesInfoTimeout)
      this.episodesInfoTimeout = null
    }
  }

  public async start(path: WsPath): Promise<void> {
    const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss'
    const wsAddr = `${protocol}://${window.location.host}${path}`
    this.ws = new WsClient(wsAddr)

    this.ws.onMessage('info', (data) => {
      if (this.onInfoCb) {
        this.onInfoCb(data.info)
      }
    })
    this.ws.onMessage('episodesInfo', (data) => {
      if (this.onEpisodesInfoCb) {
        this.onEpisodesInfoCb(data.episodesInfo)
      }
      this.isRequestingEpisodes = false
      // this.stopRequestingEpisodes()
      this.episodesInfoTimeout = setTimeout(this.requestEpisodes.bind(this), this.timeout)
    })
    await this.ws.connect()
  }

  public async episodeStarted(_ep: EpisodesRow) {
    // pass
  }

  public async episodeEnded(ep: EpisodesRow) {
    this.ws?.send(JSON.stringify({
      event: 'episodeEnded',
      data: {
        episodeId: ep.id,
      },
    }))
  }
}
