<template>
  <div ref="el">
    {{ text }}
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, Ref } from 'vue'

defineProps<{
  text: string
}>()

const el = ref<HTMLDivElement>() as Ref<HTMLDivElement>

const adjustFontSize = () => {
  // we assume font size is given in px
  let fontSizePx = parseInt(getComputedStyle(el.value).fontSize)
  const desiredHeight = el.value.clientHeight
  el.value.style.height = 'auto'
  if (desiredHeight < el.value.clientHeight) {
    while (desiredHeight < el.value.clientHeight && fontSizePx > 20) {
      fontSizePx -= 1
      el.value.style.fontSize = `${fontSizePx}px`
    }
    // @ts-ignore
    el.value.style.height = null
  } else {
    // remove any font size set manually
    // @ts-ignore
    el.value.style.fontSize = null
    // @ts-ignore
    el.value.style.height = null
  }
}

onMounted(() => {
  adjustFontSize()
})
</script>
