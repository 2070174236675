<template>
  <v-dialog
    v-model="visible"
    max-width="1300px"
    min-height="600px"
    scrollable
    styles="position:fixed"
  >
    <v-card>
      <v-btn
        block
        @click="loadLogs()"
      >
        Refresh
      </v-btn>
      <div
        class="mt-4 mx-8"
      >
        {{ title }}
      </div>
      <div
        v-for="(logRow) in logs"
        :key="logRow.id"
      >
        <div
          v-for="(entry, idx) of logRow.log"
          :key="idx"
          class="mb-4 mx-6"
        >
          <EpisodeControlCreationLog
            :entry="entry"
            :log-type="logRow.log_type"
          />
          <v-divider />
        </div>
      </div>
      <v-btn
        block
        @click="loadLogs()"
      >
        Refresh
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { LogsRow } from '../../../../common/src/Types'
import EpisodeControlCreationLog from './EpisodeControlCreationLog.vue'
import { watch } from 'vue'

const props = defineProps<{
  modelValue: boolean,
  entityType: string,
  entityId: number,
  title: string,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', val: boolean): void
}>()

const visible = ref<boolean>(props.modelValue)

const loading = ref<boolean>(false)
const logs = ref<LogsRow[]>([])
const loadLogs = async () => {
  if (!visible.value) {
    return
  }
  if (loading.value) {
    return
  }
  loading.value = true
  const res = await fetch(`/api/logs/${props.entityType}/${props.entityId}`, {
    credentials: 'include',
  })
  if (res.status === 200) {
    try {
      const data = await res.json()
      logs.value = data.items
    } catch (e) {
      // pass
    }
  }
  loading.value = false
}

watch(() => props.modelValue, (newVal) => {
  visible.value = newVal
  loadLogs()
})
watch(visible, (newVal) => {
  emit('update:modelValue', newVal)
})

onMounted(async () => {
  loadLogs()
})
</script>
