<template>
  <div class="d-flex">
    <div
      style="flex: .2;"
      class="pa-4"
    >
      <v-btn
        block
        class="mb-4"
        @click="addFanart"
      >
        Add Fanart
      </v-btn>
      <v-btn
        block
        class="mb-4"
        color="green-darken-3"
        @click="saveFanart"
      >
        Save
      </v-btn>
    </div>
    <div
      style="flex: 1"
      class="pa-4"
    >
      <div v-if="fanart.length === 0">
        No fanart added yet
      </div>
      <div v-else>
        <v-list>
          <v-list-item
            v-for="item in fanart"
            :key="item.id"
          >
            <div class="d-flex">
              <div
                class="fanart-preview"
                :style="{backgroundImage: `url(${item.url})`}"
              />
              <v-checkbox
                v-model="item.active"
                style="flex: 0 1 auto;"
              />
              <div style="flex: 1 1 auto">
                <v-text-field
                  v-model="item.url"
                  label="Image URL"
                  density="compact"
                />
                <v-btn
                  density="compact"
                  color="red-darken-4"
                  @click="remove(item.id)"
                >
                  Remove
                </v-btn>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref} from 'vue'
import { FanartRow } from '../../../../common/src/Types'
import { toast } from '../../toast'

const fanart = ref<FanartRow[]>([])

const addFanart = async () => {
  fanart.value.unshift({ id: 0, url: '', active: true })
}

const remove = async (id: number) => {
  fanart.value = fanart.value.filter(fanart => fanart.id !== id)
}

const loadFanart = async () => {
  const res = await fetch(`/api/fanart`, {
    credentials: 'include',
  })
  if (res.status === 200) {
    try {
      const data = await res.json()
      fanart.value = data.items
    } catch (e) {
      // pass
      toast('Unable to load fan art', 'error')
    }
  }
}

const saveFanart = async () => {
  const res = await fetch(`/api/fanart`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ items: fanart.value.slice().reverse() }),
  })
  if (res.status === 200) {
    try {
      const data = await res.json()
      fanart.value = data.items
      toast('Fan art saved', 'success')
    } catch (e) {
      // pass
      toast('Unable to save fan art', 'error')
    }
  }
}

onMounted(async () => {
  await loadFanart()
})
</script>
<style>
.fanart-preview {
  background-size: contain;
  width: 150px;
  height: 150px;
}
</style>
