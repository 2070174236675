<template>
  <v-form
    ref="form"
    class="d-flex"
  >
    <div
      style="flex: .2;"
      class="pa-4"
    >
      <v-btn
        :disabled="generating"
        block
        class="mb-4"
        color="blue-grey-darken-2"
        @click="createPollData"
      >
        Make a GPT request
      </v-btn>
      <v-btn
        block
        class="mb-4"
        @click="addPollOption"
      >
        + Custom Poll Option
      </v-btn>
      <v-btn
        :disabled="!canCreatePoll"
        block
        @click="createPoll"
      >
        Create Poll
      </v-btn>
    </div>
    <div
      style="flex: 1"
      class="pa-4"
    >
      <v-textarea
        v-model="promptText"
        label="Prompt"
        auto-grow
        variant="outlined"
        bg-color="grey-darken-4"
        :rules="textRules"
      />
      <div v-if="generating">
        Generating poll data...
      </div>
      <div v-else-if="!pollData || pollData.choices.length === 0">
        No poll data generated yet
      </div>
      <div v-else>
        <v-text-field
          v-model="pollData.title.text"
          label="Poll Title"
          density="compact"
          :rules="titleRules"
        />
        Select 2-4 poll choices and optionally adjust the poll texts:
        <v-list>
          <v-list-item
            v-for="(_question, idx) in pollData.choices"
            :key="idx"
          >
            <div class="d-flex flex-column">
              <div class="d-flex flex-row pt-1">
                <v-checkbox
                  v-model="activeChoices"
                  style="flex: 0 1 auto;"
                  :value="idx"
                />
                <v-textarea
                  v-model="pollData.choices[idx].text"
                  label="Episode Prompt"
                  density="compact"
                  :rules="textRules"
                  auto-grow
                  variant="outlined"
                />
              </div>
              <v-divider v-if="idx !== pollData.choices.length - 1" />
            </div>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </v-form>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { PollData } from '../../../../common/src/Types'
import { toast } from '../../toast'

const generating = ref<boolean>(false)
const pollData = ref<PollData | null>(null)
const activeChoices = ref<number[]>([])

const form = ref<any>()

const promptText = ref<string>(``)

const titleRules = [
  (v: string) => v.length <= 60 || 'Maximum length: 60',
  (v: string) => v.length > 0 || 'Minimum length: 1',
]
const textRules = [
  (v: string) => v.length > 0 || 'Minimum length: 1',
]

const activePollData = computed((): PollData => ({
  title: {
    text: pollData.value ? pollData.value.title.text : '',
  },
  choices: pollData.value ? pollData.value.choices.filter((_q, idx) => activeChoices.value.includes(idx)) : [],
}))

const canCreatePoll = computed((): boolean => {
  if (generating.value) {
    return false
  }
  if (!pollData.value) {
    return false
  }
  if (activePollData.value.choices.length < 2 || activePollData.value.choices.length > 4) {
    return false
  }
  for (const rule of titleRules) {
    if (rule(activePollData.value.title.text) !== true) {
      return false
    }
  }
  for (const choice of activePollData.value.choices) {
    for (const rule of textRules) {
      if (rule(choice.text) !== true) {
        return false
      }
    }
  }
  return true
})

const addPollOption = () => {
  if (!pollData.value) {
    pollData.value = {
      title: {
        text: 'Which episode should play next?',
      },
      choices: [
        { text: '', letter: '' },
      ],
    }
  } else {
    pollData.value.choices.push({ text: '', letter: '' })
  }
  activeChoices.value.push(pollData.value.choices.length - 1)
  form.value.validate()
}

const createPollData = async () => {
  generating.value = true
  pollData.value = null
  activeChoices.value = []
  try {
    const res = await fetch(`/api/_generate_poll_data`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt: promptText.value }),
    })
    if (res.status === 200) {
      const json = await res.json()
      pollData.value = json.pollData
    } else {
      toast('An error occured while creating the poll data...', 'error')
    }
  } catch (e) {
    toast('An error occured while creating the poll data...', 'error')
  }
  generating.value = false
  form.value.validate()
}
const createPoll = async () => {
  if (!pollData.value) {
    return
  }

  generating.value = true

  const res = await fetch(`/api/_create_poll`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      pollData: activePollData.value,
    }),
  })
  if (res.status === 200) {
    toast('Poll created!', 'success')
  } else {
    toast('An error occured while creating the poll...', 'error')
  }
  generating.value = false
}
</script>
