<template>
  <div
    class="episode"
    :class="episodeClasses"
  >
    <IcBackground
      :scene="scene"
      :next-scene="nextScene"
      :transitioning="transitioning"
      :modifiers="modifiers"
    />
    <div
      v-if="ADVANCE_MANUALLY"
      style="position: absolute; top: 5; left: 5; background: black;"
      @click="next"
    >
      NEXT
      {{ transitioning }}
    </div>

    <EpisodePlayerTitle
      v-if="scriptPointer < 0"
      :episode="episode"
    />

    <EpisodePlayerLine
      :vn-player-lines="vnPlayerLines"
      :script-pointer="scriptPointer"
      :show-characters="showCharacters"
      :target-text="targetText"
      :is-typing="isTyping"
      :typing-speed="typingSpeed"
      :info="info"
      :transitioning="transitioning"
      @finished-typing="onFinishedTyping"
    />
  </div>
</template>
<script setup lang="ts">
import { captureMessage } from '@sentry/core'
import { buildVnPlayerLines } from '../util'
import { CHARACTERS, CharacterId, findCharacter, ORIGAMI_HYPER_TEMPO } from '../../../common/src/Characters'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { EpisodesRow } from '../../../common/src/EpisodesRow'
import { getAudioContext, loadAudioBuffers, determineTempo, determinePitch } from '../audio'
import { getSceneById } from '../../../common/src/Scenes'
import { SceneDef, QueueInfoData, ScriptLineType, ModifierName, getModifierValue } from '../../../common/src/Types'
import { TtsPlayer } from '../TtsPlayer'
import { useRouter } from 'vue-router'
import { VnPlayerLine } from '../Types'
import EpisodePlayerLine from './EpisodePlayer/EpisodePlayerLine.vue'
import EpisodePlayerTitle from './EpisodePlayer/EpisodePlayerTitle.vue'
import IcBackground from './IcBackground.vue'

const router = useRouter()

// Debug settings
const SKIP_TITLE = false
const STAY_ON_TITLE = false
const ADVANCE_MANUALLY = false
const PLAY_AUDIO = true
const TRANSITION_FACTOR = 1

const TYPING_DELAY_MS = 30
const TEXT_DISSAPEAR_MS_FACTOR = 50
const TITLE_DELAY_MS_FACTOR = 150

const props = defineProps<{
  episode: EpisodesRow,
  nextEpisode: EpisodesRow | null,
  info: QueueInfoData | null,
}>()

const emit = defineEmits<{
  (e: 'ended', val: EpisodesRow): void
  (e: 'started', val: EpisodesRow): void
}>()

const isTyping = ref<boolean>(false)
const scriptPointer = ref<number>(-1)
const transitioning = ref<number>(-1)
const showCharacters = ref<boolean>(true)
const vnPlayerLines = ref<VnPlayerLine[]>([])

const getLine = (idx: number) => {
  if (idx >= 0 && idx < vnPlayerLines.value.length) {
    return vnPlayerLines.value[idx]
  }
  return null
}
const line = computed((): VnPlayerLine | null => getLine(scriptPointer.value))
const prevLine = computed((): VnPlayerLine | null => getLine(scriptPointer.value - 1))
const nextLine = computed((): VnPlayerLine | null => getLine(scriptPointer.value + 1))

const scene = computed((): SceneDef => {
  let l = line.value
  if (!l) {
    if (scriptPointer.value < 0 && vnPlayerLines.value.length > 0) {
      l = vnPlayerLines.value[0]
    } else if (scriptPointer.value > vnPlayerLines.value.length - 1 && vnPlayerLines.value.length > 0) {
      // continue showing the last scene when we exceeded the player lines
      // in order to not switch back to initial scene on end of an episode
      l = vnPlayerLines.value[vnPlayerLines.value.length - 1]
    }
  }
  return getSceneById(l?.sceneId || null)
})

const nextScene = computed(() => {
  return getSceneById(nextLine.value?.sceneId || props.nextEpisode?.scene_id || null)
})

const targetText = computed((): string => {
  if (!line.value) {
    return ''
  }
  return line.value.actor
    ? `${line.value.actor}: ${line.value.text}`
    : `${line.value.text}`
})

const typingSpeed = computed((): number => {
  return (origamiHyperMode.value && speakerCharacter.value?.id === CharacterId.ORIGAMI)
    ? (TYPING_DELAY_MS / ORIGAMI_HYPER_TEMPO)
    : TYPING_DELAY_MS
})

const modifiers = computed(() => props.info?.modifiers.filter(m => m.value) || [])

const episodeClasses = computed((): string[] => {
  const classes = []
  for (const modifier of modifiers.value) {
    classes.push('modifier-' + modifier.name)
  }
  return classes
})

const origamiHyperMode = computed(() => getModifierValue(modifiers.value, ModifierName.ORIGAMI_HYPER))

const speakerCharacter = computed(() => {
  const ch = findCharacter(line.value?.actor || '')
  if (!ch) {
    return null
  }
  const episodeCharacterIds = props.episode.character_ids || CHARACTERS.map(ch => ch.id)
  return episodeCharacterIds.includes(ch.id) ? ch : null
})

let audioBuffers: Record<string, AudioBuffer> = {}

const ttsPlayer = new TtsPlayer()

watch(modifiers, (val) => {
  ttsPlayer.setPitch(determinePitch(val, speakerCharacter.value, line.value))
  ttsPlayer.setTempo(determineTempo(val, speakerCharacter.value, line.value))
}, { deep: true })

const timeouts: any[] = []
const timeoutWithVoice = async (
  callback: () => void,
  ms: number,
  buffer: AudioBuffer | undefined,
  doType: boolean,
) => {
  // await the audio and the typing
  // when there is no audio OR no typing going on, also wait the given ms

  const promises: Promise<void>[] = []

  if (PLAY_AUDIO && buffer) {
    const play = async () => {
      await ttsPlayer.playBuffer(
        buffer,
        determinePitch(modifiers.value, speakerCharacter.value, line.value),
        determineTempo(modifiers.value, speakerCharacter.value, line.value),
      )
      await new Promise<void>((resolve) => {
        setTimeout(resolve, 250)
      })
    }
    promises.push(play())
  }

  if (doType) {
    promises.push(type())
  }

  if (promises.length < 2) {
    promises.push(new Promise((resolve, _reject) => {
      timeouts.push(setTimeout(resolve, ms))
    }))
  }

  if (!ADVANCE_MANUALLY) {
    const checkAudioContext = () => {
      // audio seems to still be running
      if (getAudioContext().state === 'running') {
        timeouts.push(setTimeout(checkAudioContext, 5000))
        return
      }

      captureMessage('line timed out', {
        level: 'error',
        tags: {
          episodeId: props.episode.id,
        },
        extra: {
          scriptPointer: scriptPointer.value,
          currentTime: getAudioContext().currentTime,
          state: getAudioContext().state,
        },
      })
      // reload page because something went wrong
      router.go(0)
    }
    timeouts.push(setTimeout(checkAudioContext, 45000))
  }

  await Promise.allSettled(promises)

  if (!ADVANCE_MANUALLY) {
    callback()
  }
}

const cleanup = () => {
  while (timeouts.length) {
    const timeout = timeouts.shift()
    try {
      clearTimeout(timeout)
    } catch (e) {
      // pass
    }
  }
  ttsPlayer.cleanup()
}

const showTitle = () => {
  emit('started', props.episode)
  if (SKIP_TITLE) {
    if (!ADVANCE_MANUALLY) {
      next()
    }
  } else if (!STAY_ON_TITLE) {
    scriptPointer.value = -1
    timeoutWithVoice(
      next,
      (props.episode.title?.text || '').length * TITLE_DELAY_MS_FACTOR,
      props.episode.title?.voicefile ? audioBuffers[voiceurl(props.episode.title.voicefile)] : undefined,
      false,
    )
  }
}

const onFinishedTyping = () => {
  isTyping.value = false
}

const type = async (): Promise<void> => {
  return new Promise((resolve, _reject) => {
    isTyping.value = true
    const stopWatching = watch(isTyping, (newVal) => {
      if (newVal === false) {
        resolve()
        stopWatching()
      }
    })
  })
}

const reset = () => {
  cleanup()
  isTyping.value = false
}

const next = async () => {
  cleanup()
  if (nextLine.value && nextLine.value.type === ScriptLineType.LOCATION) {
    // change the location
    isTyping.value = false
    showCharacters.value = false

    const transitionStart = () => {
      transitioning.value = 0
      transitionStep()
    }
    const transitionEnd = () => {
      transitioning.value = -1
      scriptPointer.value++
      showCharacters.value = true
      next()
    }
    const transitionStep = () => {
      transitioning.value += 2
      if (transitioning.value < 100) {
        timeouts.push(setTimeout(transitionStep, 10 * TRANSITION_FACTOR))
      } else {
        timeouts.push(setTimeout(transitionEnd, 400 * TRANSITION_FACTOR))
      }
    }
    timeouts.push(setTimeout(transitionStart, 400 * TRANSITION_FACTOR))
    return
  }
  scriptPointer.value++

  if (!line.value) {
    reset()
    timeouts.push(setTimeout(ended, 2000))
    return
  }

  timeoutWithVoice(
    next,
    targetText.value.length * (TYPING_DELAY_MS + TEXT_DISSAPEAR_MS_FACTOR),
    line.value.voicefile ? audioBuffers[voiceurl(line.value.voicefile)] : undefined,
    true,
  )
}

const ended = async () => {
  emit('ended', props.episode)
}

const voiceurl = (f: string) => `/data/episodes/${props.episode.episode_id}/${f}`

const onNewEpisode = async () => {
  vnPlayerLines.value = buildVnPlayerLines(props.episode)
  scriptPointer.value = -1
  reset()

  const sources: string[] = []
  if (props.episode.title?.voicefile) {
    sources.push(props.episode.title?.voicefile)
  }
  for (const line of vnPlayerLines.value) {
    if (line.voicefile) {
      sources.push(line.voicefile)
    }
  }
  audioBuffers = await loadAudioBuffers(sources.map(voiceurl))
  showTitle()
}

onMounted(async () => {
  await onNewEpisode()
})

onBeforeUnmount(() => {
  cleanup()
})
</script>
