<template>
  <div class="magic-dust">
    <canvas ref="canvas" />
  </div>
</template>
<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, Ref } from 'vue'

const randomNorm = (mean: number, stdev: number): number => {
  return Math.abs(Math.round((Math.random() * 2 - 1) + (Math.random() * 2 - 1) + (Math.random() * 2 - 1)) * stdev) + mean
}

const canvas = ref<HTMLCanvasElement>() as Ref<HTMLCanvasElement>

const W = 960
const H = 540

let unmounted = false

onMounted(() => {
  const particles: any[] = []
  const particleSize = 2
  const particleCount = 250

  canvas.value.width = 960
  canvas.value.height = 540

  const ctx = canvas.value.getContext('2d') as CanvasRenderingContext2D
  ctx.globalCompositeOperation = 'lighter'

  class Particle {
    h: number
    s: number
    l: number
    a: number
    color: string
    shadowcolor: string
    x: number
    y: number
    direction: { x: number, y: number }
    radius: number
    scale: number
    rotation: number
    vx: number
    vy: number
    valpha: number
    grad: CanvasGradient
    constructor() {
      //---------------------- PARTICLE COLORS
      this.h = 180
      this.s = Math.round(100 * Math.random() + 30)
      this.l = Math.round(150 * Math.random() + 100)
      this.a = .7 * Math.random()

      this.color = 'hsla(' + this.h + ',' + this.s + '%,' + this.l + '%,' + (this.a) + ')'
      this.shadowcolor = 'hsla(' + this.h + ',' + this.s + '%,' + this.l + '%,' + (this.a - 0.55) + ')'
      //--------------------------------------

      this.x = Math.random() * W
      this.y = Math.random() * H
      this.direction = {
        x: -1 + Math.random() * 2,
        y: -1 + Math.random() * 2,
      }

      this.radius = randomNorm(0, particleSize)
      this.scale = 0.7 * Math.random() + 0.5
      this.rotation = Math.PI / 4 * Math.random()

      this.grad = ctx.createRadialGradient(this.x, this.y, this.radius, this.x, this.y, 0)
      this.grad.addColorStop(0, this.color)
      this.grad.addColorStop(1, this.shadowcolor)

      this.vx = (2 * Math.random() + 4) * .01 * this.radius
      this.vy = (2 * Math.random() + 4) * .01 * this.radius

      this.valpha = 0.01 * Math.random() - 0.02
    }

    move() {
      this.x += this.vx * this.direction.x
      this.y += this.vy * this.direction.y
      this.rotation += this.valpha
    }

    changeDirection(axis: 'x' | 'y') {
      this.direction[axis] *= -1
      this.valpha *= -1
    }

    draw() {
      ctx.save()
      ctx.translate(this.x, this.y)
      ctx.rotate(this.rotation)
      ctx.scale(1, this.scale)

      this.grad = ctx.createRadialGradient(0, 0, this.radius, 0, 0, 0)
      this.grad.addColorStop(1, this.color)
      this.grad.addColorStop(0, this.shadowcolor)
      ctx.beginPath()
      ctx.fillStyle = this.grad
      ctx.arc(0, 0, this.radius, 0, Math.PI * 2, false)
      ctx.fill()
      ctx.restore()
    }

    boundaryCheck() {
      if (this.x >= W * 1.2) {
        this.x = W * 1.2
        this.changeDirection('x')
      } else if (this.x <= -W * 0.2) {
        this.x = -W * 0.2
        this.changeDirection('x')
      }
      if (this.y >= H * 1.2) {
        this.y = H * 1.2
        this.changeDirection('y')
      } else if (this.y <= -H * 0.2) {
        this.y = -H * 0.2
        this.changeDirection('y')
      }
    }
  }

  function clearCanvas() {
    ctx.clearRect(0, 0, W, H)
  }

  function createParticles() {
    for (let i = 0; i < particleCount; i++) {
      particles.push(new Particle())
    }
  }

  function drawParticles() {
    particles.forEach(p => {
      p.draw()
    })
  }

  function updateParticles() {
    particles.forEach(p => {
      p.move()
      p.boundaryCheck()
    })
  }


  function animateParticles() {
    if (unmounted) {
      return
    }
    clearCanvas()
    drawParticles()
    updateParticles()
    requestAnimationFrame(animateParticles)
  }

  createParticles()
  animateParticles()
})

onBeforeUnmount(() => {
  unmounted = true
})
</script>
<style lang="scss">
.magic-dust {
  width: 1920px;
  height: 1080px;
  position: absolute;
  left: 0;
  top: 0;
  canvas {
    width: 1920px;
    height: 1080px;
  }
}
</style>
