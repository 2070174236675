<template>
  <div class="d-flex my-2">
    <div
      styles="flex: 1"
    >
      <PaginationNav
        :pagination="p"
        @click="onPagination"
      />
      <PollRow
        v-for="poll in polls"
        :key="poll.id"
        :poll="poll"
        class="ma-2 pa-5"
      />
      <PaginationNav
        :pagination="p"
        @click="onPagination"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { PaginationDef } from '../../Types'
import PaginationNav from './PaginationNav.vue'
import { PollsRow } from '../../../../common/src/Types'
import PollRow from './PollRow.vue'

const polls = ref<PollsRow[]>([])
const p = ref<PaginationDef>({ offset: 0, limit: 10, total: 0 })

const onPagination = async (args: {limit: number, offset: number }) => {
  p.value.limit = args.limit
  p.value.offset = args.offset
  await loadPolls()
}

const loadPolls = async () => {
  const res = await fetch(`/api/polls?offset=${p.value.offset}&limit=${p.value.limit}`, {
    credentials: 'include',
  })
  if (res.status === 200) {
    try {
      const data = await res.json()
      polls.value = data.items
      p.value.total = data.total
    } catch (e) {
      // pass
    }
  }
}

onMounted(async () => {
  await loadPolls()
})
</script>
