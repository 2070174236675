<template>
  <v-container>
    <div class="d-flex">
      <div class="ma-1 flex-1">
        <v-text-field
          v-model="speakerId"
          label="speakerId"
        />
        <v-select
          v-model="speakerId"
          label="speakerId"
          :items="speakerIds"
        />
        <v-textarea
          v-model="text"
          label="text"
        />
        <v-btn @click="testVoice">
          Submit
        </v-btn>
      </div>
      <div class="ma-1 flex-1">
        <div>
          <v-text-field
            v-model="pitch"
            label="pitch"
            type="text"
          />
        </div>
        <div>
          <v-text-field
            v-model="rate"
            label="rate"
            type="text"
          />
        </div>

        <v-btn
          v-for="(s, idx) in synths"
          :key="idx"
          block
          class="mb-2"
          @click="play(s.buf)"
        >
          Play again {{ s.file }} {{ s.speakerId }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { loadAudioBuffer } from '../../audio'
import { TtsPlayer } from '../../TtsPlayer'

const speakerIds = ['p225', 'p226', 'p227', 'p228', 'p229', 'p230', 'p231', 'p232', 'p233', 'p234', 'p236', 'p237', 'p238', 'p239', 'p240', 'p241', 'p243', 'p244', 'p245', 'p246', 'p247', 'p248', 'p249', 'p250', 'p251', 'p252', 'p253', 'p254', 'p255', 'p256', 'p257', 'p258', 'p259', 'p260', 'p261', 'p262', 'p263', 'p264', 'p265', 'p266', 'p267', 'p268', 'p269', 'p270', 'p271', 'p272', 'p273', 'p274', 'p275', 'p276', 'p277', 'p278', 'p279', 'p280', 'p281', 'p282', 'p283', 'p284', 'p285', 'p286', 'p287', 'p288', 'p292', 'p293', 'p294', 'p295', 'p297', 'p298', 'p299', 'p300', 'p301', 'p302', 'p303', 'p304', 'p305', 'p306', 'p307', 'p308', 'p310', 'p311', 'p312', 'p313', 'p314', 'p316', 'p317', 'p318', 'p323', 'p326', 'p329', 'p330', 'p333', 'p334', 'p335', 'p336', 'p339', 'p340', 'p341', 'p343', 'p345', 'p347', 'p351', 'p360', 'p361', 'p362', 'p363', 'p364', 'p374', 'p376']

const speakerId = ref<string>('p225')
const text = ref<string>('')

const pitch = ref<string>('1.00')
const rate = ref<string>('1.00')

const ttsPlayer = new TtsPlayer()

const synths = ref<{ speakerId: string, buf: AudioBuffer, file: string }[]>([])

const play = async (buf: AudioBuffer) => {
  await ttsPlayer.playBuffer(buf, parseFloat(pitch.value), parseFloat(rate.value))
}

const testVoice = async () => {
  const res = await fetch(`/api/_test_voice`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text: text.value, speakerId: speakerId.value }),
  })
  const data = await res.json()
  try {
    const synth = {
      speakerId: speakerId.value,
      file: data.file,
      buf: await loadAudioBuffer('/data/test_voice/' + data.file),
    }
    synths.value.push(synth)
    play(synth.buf)
  } catch (e) {
    console.log('unable to load audio buffer', e)
    return
  }
}
</script>
