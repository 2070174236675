<template>
  <div>
    <hr v-if="!isFirst">
    <div
      class="episode-info"
      :class="`episode-info-${episodeInfo.type}`"
    >
      <div class="episode-info-title">
        <v-icon
          v-if="props.episodeInfo.type === PromptType.TWITCH_POLL || props.episodeInfo.type === 'poll'"
          icon="mdi-chart-box"
        />
        <v-icon
          v-if="props.episodeInfo.type === PromptType.TWITCH_SEQUEL_COMMAND || props.episodeInfo.type === PromptType.TWITCH_SEQUEL_REDEMPTION"
        >
          mdi-comment-arrow-right
        </v-icon>
        <span
          v-if="props.episodeInfo.type === PromptType.TWITCH_COMMAND || props.episodeInfo.type === PromptType.TWITCH_REDEMPTION"
          class="twitch-icon"
        >
          <TwitchSvg />
        </span>
        {{ episodeInfo.title }}
      </div>
      <div
        v-if="episodeInfo.description"
        class="episode-info-description"
      >
        {{ episodeInfo.description }}
      </div>
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { onMounted } from 'vue'
import { PromptType } from '../../../../common/src/PromptDef'
import TwitchSvg from './TwitchSvg.vue'



const props = defineProps<{
  episodeInfo: any,
  isFirst: boolean,
}>()

onMounted(() => {
  console.log(props.episodeInfo.type)
})
</script>
