<template>
  <v-dialog
    v-model="visible"
    max-width="600px"
  >
    <v-card
      class="pa-6 d-flex flex-column justify-center" 
      min-height="250px"
    >
      <div
        class="speech-bubbles-container"
      >
        <v-progress-circular 
          v-if="loading"
          model-value="20"
        />
        <div
          v-else
          class="speech-bubble left"
        >
          <div
            v-if="!isSequel"
            class="ml-2 font-weight-bold text-subtitle-1 text-medium-emphasis"
          >
            <span
              :class="twitchColor"
            >@{{ name }}</span> redeemed ✨Episode Topic✨ 
          </div>
          <div
            v-else
            class="ml-2 font-weight-bold text-subtitle-1 text-medium-emphasis"
          >
            <span
              :class="twitchColor"
            >@{{ name }}</span> redeemed ⏩Sequel Episode⏩ 
          </div>
          <div
            v-if="date.valueOf()"
            class="ml-2 mb-2 text-subtitle-2 text-medium-emphasis"
          >
            {{ readableDate }}
          </div>
          <v-divider />
          <div
            v-if="inspirationPrompt.valueOf()"
            class="ml-2 my-4 text-body-1"
          >
            {{ inspirationPrompt.valueOf() }}
          </div>
          <v-btn
            v-if="inspirationPrompt.valueOf()"
            class="hover-copy-button"
            size="x-small"
            icon="mdi-clipboard-file-outline"
            @click="copyPromptToClipboard()"
          />
        </div>
      </div>
      <v-btn
        v-if="!loading"
        class="mt-2"
        @click="loadInspirationPrompt()"
      >
        Another prompt?
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { watch } from 'vue'
import { toast } from '../toast'
import { twitchColorToId } from '../../../common/src/TwitchColors'
import { handleCopyingToClipboard } from '../util'
import { format, formatDistanceToNow } from 'date-fns'

const props = defineProps<{
  modelValue: boolean,
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', val: boolean): void
}>()

const visible = ref<boolean>(props.modelValue)

const loading = ref<boolean>(false)
const inspirationPrompt = ref<string>('')
const date = ref<string>('')
const name = ref<string>('')
const color = ref<string>('')
const isSequel = ref<boolean>(false)
const loadInspirationPrompt = async () => {
  if (!visible.value) {
    return
  }
  if (loading.value) {
    return
  }
  loading.value = true
  const res = await fetch(`/api/_get_random_prompt`)
  if (res.status === 200) {
    try {
      const data = await res.json()
      inspirationPrompt.value = data.prompt
      date.value = data.date
      name.value = data.name
      color.value = data.color
      isSequel.value = data.isSequel
    } catch (e) {
      toast(`Error! ${e}`, 'error')
    }
  }
  loading.value = false
}

const twitchColor = computed(() => {
  return `twitch-color-${twitchColorToId(color.value)}`
})

const readableDate = computed(() => {
  if (!date.value) {
    return ''
  }
  return formatDistanceToNow(new Date(date.value),
  {includeSeconds: true, addSuffix: true})
})


const copyPromptToClipboard = async () => {
  if (!inspirationPrompt.value) {
    return
  }
  handleCopyingToClipboard(inspirationPrompt.value)
  toast('Copied prompt to clipboard', 'success')
}

watch(() => props.modelValue, (newVal) => {
  visible.value = newVal
  loadInspirationPrompt()
})
watch(visible, (newVal) => {
  emit('update:modelValue', newVal)
})

onMounted(async () => {
  loadInspirationPrompt()
})
</script>
