<template>
  <div class="visual-container episodes-info-container">
    <div
      v-if="generatingCount"
      class="episodes-generation-container"
    >
      <v-icon
        icon="mdi-loading"
        class="mdi-spin"
      /> Generating {{ generatingCount }} {{ generatingCount === 1 ? 'episode' : 'episodes' }}
    </div>

    <TransitionGroup
      name="list"
      tag="div"
      class="episodes-info-container-inner"
    >
      <EpisodesInfoEntry
        v-for="(episodeInfo, idx) in episodeInfos"
        :key="episodeInfo.id"
        :is-first="idx === 0"
        :episode-info="episodeInfo"
      />
    </TransitionGroup>

    <div
      v-if="info"
      class="episodes-ready-container"
    >
      {{ info.unplayedEpisodesWithPrompt + info.unplayedEpisodesWithoutPrompt }} episodes <span class="twitch-icon">
        <TwitchSvg />
      </span> {{ info.unplayedEpisodesWithPrompt }}
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { computed } from 'vue'
import { EpisodesRow } from '../../../../common/src/EpisodesRow'
import { QueueInfoData } from '../../../../common/src/Types'
import { PromptType } from '../../../../common/src/PromptDef'
import EpisodesInfoEntry from './EpisodesInfoEntry.vue'
import TwitchSvg from './TwitchSvg.vue'

const props = defineProps<{
  info: QueueInfoData | null,
}>()

const generatingCount = computed<number>(() => {
  if (!props.info) {
    return 0
  }
  return props.info.promptsQueued + (props.info.episodesProcessing ? 1 : 0)
})

const episodedToEpisodeInfo = (episode: EpisodesRow) => {
  if (episode.prompt_topic && episode.prompt_username && (episode.prompt_type === PromptType.TWITCH_REDEMPTION || episode.prompt_type === PromptType.TWITCH_COMMAND)) {
    return {
      type: episode.prompt_type,
      title: `${episode.prompt_username}'s Prompt`,
      description: episode.prompt_topic,
      id: episode.id,
    }
  } else if (episode.prompt_topic && (episode.prompt_type === PromptType.TWITCH_SEQUEL_COMMAND || episode.prompt_type === PromptType.TWITCH_SEQUEL_REDEMPTION)) {
    return {
      type: episode.prompt_type,
      title: episode.title?.text || `Sequel to episode #${episode.prompt_prequel_episode_id}`,
      description: episode.prompt_topic,
      id: episode.id,
    }
  }
  if (episode.prompt_topic && episode.prompt_type === PromptType.TWITCH_POLL) {
    return {
      type: episode.prompt_type,
      title: `Poll-Prompt`,
      description: episode.prompt_topic,
      id: episode.id,
    }
  }
  return {
    type: episode.prompt_type || 'generic',
    title: episode.title?.text,
    description: '',
    id: episode.id,
  }
}

const episodeInfos = computed(() => {
  const episodeInfos = (props.info?.episodes || []).map(ep => episodedToEpisodeInfo(ep))
  if (props.info?.pollsQueued) {
    episodeInfos.splice(episodeInfos.length >= 1 ? 1 : 0, 0, {
      type: 'poll',
      title: 'Poll',
      description: '',
      id: -1,
    })
  }
  return episodeInfos
})
</script>

<style>
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.list-leave-active {
  position: absolute;
}
</style>
