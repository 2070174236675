import { DialogScriptLine, ScriptLineType } from '../../common/src/Types'
import { CharacterId, CHARACTERS, CharacterSprite, findCharacter, findSprite } from '../../common/src/Characters'
import { VnPlayerLine } from './Types'
import { getSceneById } from '../../common/src/Scenes'
import { EpisodesRow } from '../../common/src/EpisodesRow'
import { toast } from './toast'

export const buildVnPlayerLines = (episode: EpisodesRow): VnPlayerLine[] => {
  const episodeCharacterIds = episode.character_ids || CHARACTERS.map(ch => ch.id)

  let sprite1: any
  let character1Id: CharacterId | null = null
  let sprite2: any
  let character2Id: CharacterId | null = null
  let characterActive: number = 0

  const scene = getSceneById(episode.scene_id)
  let lastSceneId = scene.id

  const findCharacterIdAndSprite = (l: DialogScriptLine): { id: CharacterId, sprite: CharacterSprite } | null => {
    const ch = findCharacter(l.data.actor || '')
    if (ch && episodeCharacterIds.includes(ch.id)) {
      const sprite = l.data.actor ? findSprite(ch, l.data.emotion, scene) : null
      if (!sprite) {
        return null
      }
      return { id: ch.id, sprite }
    } else {
      return null
    }
  }

  const tmpLines: VnPlayerLine[] = []
  for (const l of episode.script || []) {
    if (l.type === ScriptLineType.DIALOG) {
      const info = findCharacterIdAndSprite(l)
      if (info) {
        if (!character1Id || character1Id === info.id) {
          sprite1 = info.sprite
          character1Id = info.id
          characterActive = 1
        } else if (!character2Id || character2Id === info.id) {
          sprite2 = info.sprite
          character2Id = info.id
          characterActive = 2
        } else {
          // character has to replace an existing character:
          // we replace the one that didnt speak last
          if (characterActive === 1) {
            sprite2 = info.sprite
            character2Id = info.id
            characterActive = 2
          } else {
            sprite1 = info.sprite
            character1Id = info.id
            characterActive = 1
          }
        }
      } else {
        if (l.data.actor) {
          characterActive = 0
        }
      }
      const actor = l.data.actor || ''
      tmpLines.push({
        sceneId: lastSceneId,
        type: l.type,
        actor,
        text: l.data.text || '',
        voicefile: l.data.voicefile || '',
        characterActive: actor ? characterActive : 0,
        character1Id: actor && character1Id ? character1Id : null,
        character1Sprite: actor ? sprite1 : null,
        character2Id: actor && character2Id ? character2Id : null,
        character2Sprite: actor ? sprite2 : null,
      })
    } else if (l.type === ScriptLineType.NARRATION) {
      tmpLines.push({
        sceneId: lastSceneId,
        type: l.type,
        actor: '',
        text: l.data.text || '',
        voicefile: l.data.voicefile || '',
        characterActive: 0,
        character1Id: character1Id ? character1Id : null,
        character1Sprite: sprite1 ?? null,
        character2Id: character2Id ? character2Id : null,
        character2Sprite: sprite2 ?? null,
      })
    } else {
      const newSceneId = l.data.sceneId || lastSceneId
      if (newSceneId === lastSceneId) {
        continue
      }
      lastSceneId = newSceneId
      sprite1 = undefined
      character1Id = null
      sprite2 = undefined
      character2Id = null
      characterActive = 0
      tmpLines.push({
        sceneId: newSceneId,
        type: l.type,
        actor: '',
        text: '',
        voicefile: '',
        characterActive: 0,
        character1Id: null,
        character1Sprite: null,
        character2Id: null,
        character2Sprite: null,
      })
    }
  }
  // don't add a location change at the end
  while (tmpLines.length > 0 && tmpLines[tmpLines.length - 1].type === ScriptLineType.LOCATION) {
    tmpLines.pop()
  }
  return tmpLines
}

export const debug = (..._data: any[]) => {
  // pass
  // console.debug(...data)
}

export const handleCopyingToClipboard = async (copyText: string) => {
  try {
    await navigator.clipboard.writeText(copyText)
  } catch (e: any) {
    toast('Error when copying!', 'error')
  }
}
