const BGM_VOLUME = .5
const PLAY_BGM = true

export class BgmPlayer {
  private bgm: HTMLAudioElement
  private stopped: boolean
  constructor() {
    this.stopped = false
    this.bgm = new Audio()
    this.bgm.volume = BGM_VOLUME
    this.bgm.loop = true
  }

  async fadeOut(): Promise<void> {
    await new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (this.stopped) {
          clearInterval(interval)
          resolve()
          return
        }
        if (!this.bgm.paused && this.bgm.volume > 0) {
          this.bgm.volume = Math.max(0, this.bgm.volume - 0.05)
        }
        if (this.bgm.paused || this.bgm.volume <= 0) {
          clearInterval(interval)
          this.bgm.pause()
          resolve()
        }
      }, 35)
    })
  }

  async fadeIn(src: string): Promise<void> {
    // fade in new bgm
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        if (this.stopped) {
          resolve()
          return
        }
        this.bgm.src = src
        if (PLAY_BGM) {
          this.bgm.play()
        }
        const interval = setInterval(() => {
          if (this.stopped) {
            clearInterval(interval)
            resolve()
            return
          }
          if (this.bgm.volume < BGM_VOLUME) {
            this.bgm.volume = Math.min(BGM_VOLUME, this.bgm.volume + 0.05)
          }
          if (this.bgm.volume >= BGM_VOLUME) {
            clearInterval(interval)
            resolve()
          }
        }, 35)
      }, 500)
    })
  }

  cleanup () {
    this.stopped = true
    if (!this.bgm.paused) {
      this.bgm.pause()
    }
  }
}
