<template>
  <div
    ref="el"
    :class="classes"
  >
    <div class="modifier-size">
      <div :style="style" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, Ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { CharacterDef, CharacterSprite } from '../../../common/src/Characters'

const props = defineProps<{
  character: CharacterDef
  sprite: CharacterSprite
}>()

const emit = defineEmits<{
  (event: 'speakstartEnded'): void
}>()

const classes = computed(() => {
  const classes = ['character']
  if (props.character) {
    classes.push('character-' + props.character.id)
  }
  return classes
})

const currentSprite = ref<CharacterSprite>(props.sprite)

const spriteImageUrl = (sprite: CharacterSprite) => {
  return `/assets/sprites/${sprite.image.split('/').map(encodeURIComponent).join('/')}`
}

const style = computed(() => ({
  backgroundImage: `url(${spriteImageUrl(currentSprite.value)})`,
  width: `${currentSprite.value.width}px`,
  height: `${currentSprite.value.height}px`,
}))

const el = ref<HTMLDivElement>() as Ref<HTMLDivElement>

const speakStartEnded = () => {
  if (el.value.classList.contains('speakstart')) {
    emit('speakstartEnded')
  }
}

watch(() => props.sprite, (newVal) => {
  const img = new Image()
  img.addEventListener('load', () => {
    currentSprite.value = newVal
  })
  img.src = spriteImageUrl(newVal)
})

onMounted(() => {
  el.value.addEventListener('animationend', speakStartEnded)
})
onBeforeUnmount(() => {
  el.value.removeEventListener('animationend', speakStartEnded)
})
</script>
