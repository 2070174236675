export const twitchColorToId = (color: string | null): number => {
  const idx = [
    '#9146FF', // DEFAULT twitch color
    '#FF0000', // -> #E83646
    '#0000FF', // -> #4F36DF
    '#008000', // -> #4F8246
    '#B22222', // -> #BA4A5A
    '#FF7F50', // -> #E88276
    '#9ACD32', // -> #ABB164
    '#FF4500', // -> #E85F46
    '#2E8B57', // -> #6A897A
    '#DAA520', // -> #D29959
    '#D2691E', // -> #CD7558
    '#5F9EA0', // -> #8894A6
    '#1E90FF', // -> #618CDF
    '#FF69B4', // -> #E875B2
    '#8A2BE2', // -> #A24FCD
    '#00FF7F', // -> #4FCF92
  ].findIndex(c => c === color)
  return Math.max(0, idx)
}
