<template>
  <v-card
    class="ma-2 pa-5"
  >
    <div
      class="d-flex"
      style="align-items: center;"
    >
      <img
        :src="`/assets/character-icons/${poll.poll_character_id}.png`"
        class="poll-character-icon mr-4"
      >
      <div>
        {{ poll.poll_data.title.text }}
      </div>
    </div>
    <div class="mb-2">
      <v-chip
        size="x-small"
        class="mr-2"
      >
        ID: {{ poll.id }}
      </v-chip>

      <v-chip
        size="x-small"
        class="mr-2"
      >
        {{ poll.status }}
      </v-chip>
      <v-chip
        size="x-small"
        class="mr-2"
      >
        CREATED: {{ poll.created }}
      </v-chip>
      <v-chip
        size="x-small"
        class="mr-2"
      >
        FINISHED: {{ poll.finished }}
      </v-chip>
    </div>
    <div>
      <div
        v-for="choice in poll.poll_data.choices"
        :key="choice.letter"
      >
        {{ choice.letter }})
        <v-icon
          v-if="poll.winning_choice === choice.letter"
          icon="mdi-trophy"
        />
        {{ choice.text }}
      </div>
    </div>

    <div>
      <v-btn @click="showTwitchData=!showTwitchData">
        TWITCH DATA
      </v-btn>
      <div v-if="showTwitchData">
        {{ poll.twitch_poll_data }}
      </div>
    </div>
    <div v-if="poll.conversation">
      <v-btn @click="showConversation=!showConversation">
        CONVERSATION
      </v-btn>
      <PollConversation
        v-if="showConversation"
        :conversation="poll.conversation"
        :character-id="poll.poll_character_id"
      />
    </div>
    <div v-if="logs.length > 0">
      <div>
        <v-btn @click="showLog=!showLog">
          LOG
        </v-btn>
      </div>
      <PollLog
        v-if="showLog"
        :logs="logs"
      />
    </div>
  </v-card>
</template>
<script setup lang="ts">
import {onMounted, ref } from 'vue'
import { LogsRow, PollsRow } from '../../../../common/src/Types'
import PollConversation from './PollConversation.vue'
import PollLog from './PollLog.vue'

const showLog = ref<boolean>(false)
const showConversation = ref<boolean>(false)
const showTwitchData = ref<boolean>(false)

const props = defineProps<{
  poll: PollsRow
}>()

const logs = ref<LogsRow[]>([])
const loadLog = async () => {
  const res = await fetch(`/api/logs/poll/${props.poll.id}`, {
    credentials: 'include',
  })
  if (res.status === 200) {
    try {
      const data = await res.json()
      logs.value = data.items
    } catch (e) {
      // pass
    }
  }
}

onMounted(async () => {
  await loadLog()
})
</script>
<style>
.poll-character-icon {
  width: 48px;
  height: 48px;
}
</style>
