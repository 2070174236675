<template>
  <div>
    <vue-draggable
      :model-value="items"
      class="v-list v-theme--dark v-list--density-default v-list--one-line"
      tag="div"
      handle=".v-list-item-title"
      item-key="id"
      @end="dragEnd"
    >
      <template #item="{ element, index }">
        <v-list-item
          :key="index"
          :title="element.title?.text"
          :subtitle="subtitleText(element)"
        >
          <template #prepend>
            <v-btn
              class="mr-3"
              icon="mdi-close"
              density="compact"
              @click="emit('remove-from-playlist', element)"
            />
          </template>
        </v-list-item>
      </template>
    </vue-draggable>
  </div>
</template>
<script setup lang="ts">
import { EpisodesRow } from '../../../../common/src/EpisodesRow'
import { PromptType } from '../../../../common/src/PromptDef'
import { DragEndEvent } from '../../../../common/src/Types'

defineProps<{
  items: EpisodesRow[],
}>()

const subtitleText = (item: EpisodesRow): string => {
  switch (item.prompt_type) {
    case PromptType.TWITCH_POLL: return '💜 Twitch poll result'
    case PromptType.TWITCH_REDEMPTION: return `💬 Redeemed by ${item.prompt_username}`
    case PromptType.TWITCH_COMMAND: return `💬 Prompted by ${item.prompt_username}`
    case PromptType.TWITCH_SEQUEL_COMMAND: return `🎬 Sequel by ${item.prompt_username}`
    case PromptType.TWITCH_SEQUEL_REDEMPTION: return `🎬 Custom sequel by ${item.prompt_username}`
    default: return ''
  }
}

const dragEnd = (evt: DragEndEvent): void => {
  emit('playlist-order-change', evt.oldIndex, evt.newIndex)
}

const emit = defineEmits<{
  (e: 'remove-from-playlist', val: EpisodesRow): void,
  (e: 'playlist-order-change', oldIndex: number, newIndex: number): void,
}>()
</script>
