export interface User {
  name: string
}

let me: null | User = null
async function init(): Promise<void> {
  const res = await fetch('/api/auth/me', {
    credentials: 'include',
  })
  me = res.status === 200 ? (await res.json()) : null
}

async function login(
  name: string,
  pass: string,
): Promise<boolean> {
  const res = await fetch('/api/auth/login', {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name, pass }),
  })
  if (res.status === 200) {
    return true
  }
  return false
}

export default {
  getMe: () => me,
  login,
  init,
}
