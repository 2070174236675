import { NonEmptyArray } from './Types'

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const shuffle = <T> (array: Array<T>): Array<T> => {
  const arr = array.slice()
  for (let i = 0; i <= arr.length - 2; i++) {
    const j = getRandomInt(1, arr.length - 1)
    const tmp = arr[i]
    arr[i] = arr[j]
    arr[j] = tmp
  }
  return arr
}

export const getRandom = <T>(array: NonEmptyArray<T>): T => {
  return array[Math.floor(Math.random() * array.length)]
}

export const sanitizeQuotations = (text: string): string => {
  return text
    .replace(/[“”„«»]/g, '"')
    .replace(/[‘’‚‹›]/g, "'")
}

export function arrayMove(arr: any[], oldIndex: number, newIndex: number) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  return arr // return, but array is also modified in place
}

export const split = (
  text: string,
  delimiter: string,
  limit: number,
): NonEmptyArray<string> => {
  if (limit === 1) {
    return [text]
  }
  let tmp = text.split(delimiter)
  if (limit > 0 && limit < tmp.length) {
    tmp = [
      ...tmp.slice(0, limit - 1),
      tmp.slice(limit - 1).join(delimiter),
    ]
  }
  return tmp as NonEmptyArray<string>
}

export const unwrap = (text: string, wrapper: string): string => {
  return text.startsWith(wrapper) && text.endsWith(wrapper)
    ? text.substring(wrapper.length, text.length - wrapper.length)
    : text
}

export const trim = (text: string, characters: string): string => {
  const chars = characters.split('')

  let posStart = 0
  for (let i = 0; i < text.length; i++) {
    if (!chars.includes(text[i])) {
      break
    }
    posStart = i + 1
  }

  if (posStart >= text.length) {
    return ''
  }

  let posEnd = -1
  for (let i = text.length -1; i >= 0; i--) {
    if (!chars.includes(text[i])) {
      break
    }
    posEnd = i
  }
  return text.substring(
    posStart,
    posEnd >= 0 ? posEnd : undefined,
  )
}

export const isUnfulfilledRequestText = (text: string): boolean => {
  return !text.includes('\n') && !!(
    // clarify
    text.match(/(Could you please clarify|However|Just to clarify|Just to confirm|I'm happy to help you with that, but|I apologize)/)
    // copyright/innappropriate etc..
    || text.match(/(copyright|inappropriate|sensitive|not suitable|sexual themes|OpenAI's use case policy|OpenAI's content guidelines)/)
    // unable
    || text.match(/(cannot|unable to|can('|’)t|not able to) (create|comply with|do|complete|fulfill?|help with|assist with|provide) (this|that|the|your)( (script|request|prompt|task))?\.?/)
    // other
    || text === 'Tbc'
    || text === 'Rerunning the app for this task...'
    || text === 'This is beyond my capabilities.'
    || text === 'What is the title of the chapter?'
  )
}
