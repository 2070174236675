import { NonEmptyArray, SceneDef } from './Types'
import { ClothingStyle } from './ClothingStyle'

export enum SceneId {
  AQUARIUM = 'aquarium',
  ARCADE = 'arcade',
  BALLROOM = 'ballroom',
  BAR = 'bar',
  BEACH = 'city beach',
  BEDROOM = 'bedroom',
  BEDROOM_EVENING = 'bedroom evening',
  BEDROOM_NIGHT = 'bedroom at night',
  BEDROOM_BOOKS = 'bedroom books',
  BEDROOM_CAMPUS = 'bedroom campus',
  BIG_CITY = 'big city',
  BIOLOGY_ROOM = 'biology room',
  BOWLING_ALLEY = 'bowling alley',
  CAFE = 'cafe',
  CAFE_AT_NIGHT_INSIDE = 'cafe at night inside',
  CAFE_AT_NIGHT_OUTSIDE = 'cozy cafe in a small street at night',
  CAFETERIA = 'school cafeteria',
  CAMPING_SPOT = 'camping spot',
  CAMPING_SPOT_NIGHT = 'camping spot, night',
  CANDLE_LIGHT_DATE = 'candle light date',
  CHEMISTRY_LAB = 'chemistry lab',
  CLASSROOM = 'classroom',
  CLASSROOM_THUNDERSTORM = 'classroom during thunderstorm',
  CLINIC = 'clinic',
  CLUB_ROOM = 'club room',
  COFFEE_SHOP = 'coffee shop',
  COMPUTER_LAB = 'computer lab',
  CONCERT_VENUE = 'concert venue',
  COOKING_CLASS = 'cooking class',
  CORRIDOR = 'corridor',
  DANCE_FLOOR_PARTY = 'dance floor party',
  DESERT = 'desert',
  DORM_HALLWAY = 'dorm hallway',
  DRIVING_LESSONS = 'driving lessons',
  FOREST = 'forest',
  GREENHOUSE = 'greenhouse',
  HIKING_TRAIL = 'hiking trail',
  ISOLATED_ROOM = 'isolated room',
  JAPANESE_FESTIVAL = 'japanese festival',
  KARAOKE = 'karaoke',
  KITCHEN = 'kitchen',
  LAKE_SHORE = 'lake_shore',
  LIBRARY = 'library',
  LIVINGROOM_DAY = 'livingroom day',
  LIVINGROOM_EVENING = 'livingroom evening',
  LIVINGROOM_NIGHT = 'livingroom night',
  MATHROOM = 'math room',
  MUSICROOM = 'music room',
  NIGHT_CLUB = 'night_club',
  OFFICE = 'office',
  ONSEN = 'onsen',
  OTAKU_ROOM = 'otaku_room',
  PARK = 'park',
  POOL = 'pool',
  POOL_PARTY = 'pool party',
  RAINFOREST = 'rainforest',
  RIVERBANK = 'riverbank',
  ROOFTOP = 'rooftop',
  ROOFTOP_SUNSET = 'rooftop at sunset',
  ROOFTOP_AT_NIGHT = 'rooftop at night',
  SCHOOL_CAMPUS = 'school campus',
  SCHOOL_CAMPUS_NIGHT = 'school campus, night',
  SCHOOL_COURTYARD = 'school courtyard',
  SCHOOL_FRONT_GATE = 'school front gate',
  SCHOOL_GYM = 'school gym',
  SHOPPING_MALL = 'shopping mall',
  SMALL_TOWN_OUTDOOR = 'small_town_outdoor',
  STADIUM = 'stadium',
  STAGE = 'stage',
  STREAMING_SETUP = 'streaming setup',
  STREETS = 'streets',
  SUPERNATURAL_CLUBROOM = 'supernatural clubroom',
  TEA_SHOP = 'tea shop',
  TEA_ROOM = 'tea room',
  TEACHERS_ROOM = 'teachers room',
  TOWN_OUTSKIRTS = 'town_outskirts',
  TRAIN = 'train',
  VENDING_MACHINE_OUTSIDE = 'vending machine outside',
  WAY_HOME_FROM_SCHOOL = 'suburban neighborhood',
  SMALL_STORE = 'small store',
  SUPERMARKET = 'supermarket',
  REMOTE_BEACH = 'remote beach',
  OCCULT_CLUB = 'occult club',
  SNOWY_LANDSCAPE = 'snowy landscape',
  CREEPY_CLASSROOM = 'creepy classroom',
  HARBOR = 'harbor with ship',
  CREEPY_SUPERNATURAL_STREET = 'creepy supernatural street',
  CLASSROOM_RAIN = 'classroom during rain',
  SHRINE = 'shrine',
  CONSTRUCTION_SITE = 'construction site',
  TRAIN_STATION = 'train station',
  CINEMA = 'cinema',
  CINEMA_HALL = 'outside a movie theater',
  GAMER_ROOM = 'dark gaming room with a gaming pc',
  SAKURA_HILL = 'sakura hill',
  POND = 'pond',
  PLAYGROUND = 'playground',
  PARK_BENCH = 'park bench',
  MEADOW = 'meadow',
  MAGICAL_CAVE = 'magical cave',
  CAVE = 'cave',
  FOUNTAIN = 'fountain plaza',
  FUTURISTIC_CITY = 'futuristic city',
  FAST_FOOD_RESTAURANT = 'fast food restaurant',
  BALCONY = 'balcony',
  ZOO = 'zoo',
  SPACESHIP = 'spaceship',
  SCIENCE_LAB = 'science lab',
  RUINS = 'ruins',
  POLICE_STATION = 'police station',
  HIGHWAY = 'highway',
  GARDEN_RAIN = 'garden rain',
  GALAXY = 'galaxy',
  ENGINEERING_WORKSHOP = 'engineering workshop',
  SCHOOL_WC = 'school wc',
  BUILDING = 'building',
  BATHROOM = 'bathroom',
  BACKYARD_DAY = 'backyard day',
  BACKYARD_SUNSET = 'backyard sunset',
  BACKYARD_NIGHT = 'backyard night',
  ABANDONED_HOUSE = 'abandoned house',
  BEDROOM_BOY = 'bedroom boy',
  PARK_FIELD = 'park field',
  RELIGIOUS_SPACE = 'religious space',
  STORAGE_ROOM = 'storage room',
  JANITOR_CLOSET = 'janitor closet',
  PODCAST_ROOM = 'podcast room',
  AUDITORIUM = 'school auditorium',
  SCHOOL_BACKYARD = 'school backyard',
  BAKERY = 'bakery',
  CHURCH = 'church',
  CODING_ROOM = 'coding room',
  CONFERENCE_ROOM = 'conference room',
  DARK_BASEMENT = 'dark basement',
  DRESSING_ROOM = 'dressing room',
  FOREST_PREHISTORIC = 'prehistoric forest',
  GAS_STATION = 'gas station',
  KITCHEN_INDUSTRIAL = 'industrial kitchen',
  MEDIEVAL_ROOM = 'medieval room',
  NURSE_OFFICE = 'nurse office',
  PARKING_LOT = 'parking lot',
  POSTAPOCALYPTIC = 'postapocalyptic',
  SCHOOL_KITCHEN = 'school kitchen',
  SCRAPYARD = 'scrap yard',
  UNDERWATER = 'underwater',
  BREAKROOM = 'breakroom',
  OUTSIDE = 'outside',
  SKY = 'sky',
  ANIMAL_SHELTER = 'animal shelter',
  CLASSROOM_SAKURA = 'sakura classroom',
  BEDROOM_KIRINO = 'kirino bedroom',
  ROOFTOP_LUNCH_AREA_SAKURA = 'sakura rooftop lunch area',
  ROOFTOP_SAKURA = 'sakura rooftop',
  COURTYARD_SAKURA = 'sakura courtyard',
  HALLWAY_SAKURA = 'sakura hallway',
  LIBRARY_SAKURA = 'sakura library',
  SCHOOL_CAMPUS_SAKURA = 'sakura school campus',
  SCHOOL_GATE_SAKURA = 'sakura school gate',
  LIBRARY_SCHOOL = 'school library',
  YATO_CLASSROOM = 'yato classroom',
  AMUSEMENT_PARK = 'amusement park',
  BEDROOM_AYAMI = 'ayami bedroom',
  LIVINGROOM_AYAMI = 'ayami livingroom',
  GARDEN = 'garden',
  BEDROOM_KAYA = 'kaya bedroom',
  LIVINGROOM_KAYA = 'kaya livingroom',
  LIVINGROOM_KIRINO = 'kirino livingroom',
  LIVINGROOM_LEMON = 'lemon livingroom',
  LIVINGROOM_MIKIKO = 'mikiko livingroom',
  SCHOOL_CAMPUS_MAGIC = 'magic school campus',
  CLASSROOM_OCCULT = 'occult classroom',
  HALLWAY_OCCULT = 'occult hallway',
  HOUSE_OCCULT = 'occult house',
  KITCHEN_OCCULT = 'occult kitchen',
  MEETING_OCCULT = 'occult meeting room',
  ROOFTOP_OCCULT = 'occult rooftop',
  BEDROOM_ORIGAMI = 'origami bedroom',
  LIVINGROOM_ORIGAMI = 'origami livingroom',
  ROOFTOP_LUNCH_AREA = 'rooftop lunch area',
  GARDEN_SAKURA = 'sakura garden',
  GARDEN_SCHOOL = 'school garden',
  KITCHEN_SAKURA = 'sakura kitchen',
  PARK_SAKURA = 'sakura park',
  ANIMAL_SHELTER_SAKURA = 'sakura shelter',
  SCIENCE_CLASSROOM = 'science classroom',
  SCIENCE_LAB_SAKURA = 'sakura science lab',
  SECRET_ROOM = 'secret room',
  TOWN_SQUARE = 'town square',
  DRAGON_LAIR = 'dragon lair',
  LEMON_COOKING_SCHOOL = 'lemon cooking school',
  KITCHEN_GIRL = 'girl kitchen',
  MAID_CAFE = 'maid cafe',
  LIBRARY_OCCULT = 'occult library',
  CLUB_ROOM_SAKURA = 'sakura club room',
  COMPUTER_LAB_SAKURA = 'sakura computer lab',
  MUSICROOM_SAKURA = 'sakura music room',
  OCCULT_CLUB_SAKURA = 'sakura occult club',
  OFFICE_SAKURA = 'sakura office',
  TEACHERS_ROOM_SAKURA = 'sakura teachers room',
  GYM_SAKURA = 'sakura gym',
  JAIL = 'jail',
  SEWERS = 'sewers',
  CATACOMBS = 'catacombs',
  ABANDONED_ALLEY = 'abandoned alley',
  QUARRY = 'quarry',
  AIRPORT = 'airport',
  PARK_EVENING = 'park evening',
  PARK_NIGHT = 'park night',
  CAMPUS_CAFERETIA = 'campus cafeteria',
  COURTROOM = 'courtroom',
  DORM_EVENING = 'dorm evening',
  DORM = 'dorm',
  FASHION_STUDIO = 'fashion studio',
  HIDEOUT = 'hideout',
  MANSION = 'mansion',
  PET_STORE = 'pet store',
  VILLAGE = 'village',
  VOID = 'void'
}

const matches = (sceneName: string, text: string): boolean => {
  const regex = new RegExp(`${sceneName.replace(/ /g, '.*')}`, 'ig')
  return regex.test(text)
}

const matchesWordsInOrder = (sceneName: string, text: string): boolean => {
  const words = sceneName.split(/\s+/g)
  let startIdx = 0
  for (const word of words) {
    startIdx = text.indexOf(word, startIdx)
    if (startIdx === -1) {
      return false
    }
  }
  return true
}

const matchesWords = (sceneName: string, text: string): boolean => {
  const words = sceneName.split(/\s+/g)
  for (const word of words) {
    const startIdx = text.indexOf(word)
    if (startIdx === -1) {
      return false
    }
  }
  return true
}

export const detectBestScene = (text: string, scenes: SceneDef[]): SceneDef | null => {
  if (!text) {
    return null
  }

  const matchers = [matchesWordsInOrder, matchesWords, matches]
  const aliases: { alias: string, sceneId: SceneId }[] = []
  scenes.sort((a, b) => a.name.length === b.name.length ? 0 : a.name.length < b.name.length ? 1 : -1)
  for (const scene of scenes) {
    for (const matcher of matchers) {
      if (matcher(scene.name, text)) {
        return scene
      }
    }
    aliases.push(...scene.aliases.map(alias => ({ alias, sceneId: scene.id })))
  }
  aliases.sort((a, b) => a.alias.length === b.alias.length ? 0 : a.alias.length < b.alias.length ? 1 : -1)
  for (const item of aliases) {
    for (const matcher of matchers) {
      if (matcher(item.alias, text)) {
        return scenes.find(scene => scene.id === item.sceneId) as SceneDef
      }
    }
  }

  return null
}

export const getSceneById = (sceneId: SceneId | null): SceneDef => {
  const scene = SCENES.find(s => s.id === sceneId)
  return scene || SCENE_CLASSROOM
}

const SCENE_CLASSROOM: SceneDef = {
  id: SceneId.CLASSROOM,
  name: 'classroom',
  aliases: [],
  image: 'classroom.webp',
  imageNiji5: 'classroom.webp',
  description: '',
  clothingStyle: ClothingStyle.SCHOOL,
}

export const SCENES: NonEmptyArray<SceneDef> = [
  SCENE_CLASSROOM,
  {
    id: SceneId.MATHROOM,
    name: 'math class',
    aliases: ['algebra class', 'zerasu class'],
    image: 'classroom-math.webp',
    imageNiji5: 'classroom-math.webp',
    description: 'daytime',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.MUSICROOM,
    name: 'music class',
    aliases: ['music room', 'choir room'],
    image: 'image_2023-03-05_01-55-37.webp',
    imageNiji5: 'classroom-music.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.CAFE,
    name: 'cafe ',
    aliases: [`café`, `bakery`],
    image: 'image_2023-03-04_17-29-00.webp',
    imageNiji5: 'cafe-street.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.STREETS,
    name: 'street',
    aliases: [],
    image: 'image_2023-03-04_17-48-35.webp',
    imageNiji5: 'street.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.TRAIN,
    name: 'train',
    aliases: ['subway'],
    image: 'image_2023-03-04_22-59-51.webp',
    imageNiji5: 'train-new.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.PARK,
    name: 'park',
    aliases: [],
    image: 'image_2023-03-04_23-15-43.webp',
    imageNiji5: 'park.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.ROOFTOP,
    name: 'roof',
    aliases: [],
    image: 'image_2023-03-04_23-31-46.webp',
    imageNiji5: 'school-rooftop-day.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.ROOFTOP_SUNSET,
    name: 'roof sunset',
    aliases: ['roof morning', 'roof evening'],
    image: 'image_2023-03-04_23-31-46.webp',
    imageNiji5: 'school-rooftop-evening.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.CAFETERIA,
    name: 'academy cafe',
    aliases: ['cafeteria', 'lunch room', 'school dining', 'uni cafe', 'college cafe'],
    image: 'image_2023-03-04_23-39-07.webp',
    imageNiji5: 'cafeteria.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.BEACH,
    name: 'city beach',
    aliases: [],
    image: 'image_2023-03-07_00-57-28.webp',
    imageNiji5: 'beach.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LIBRARY,
    name: 'library',
    aliases: [],
    image: 'image_2023-03-05_02-44-52.webp',
    imageNiji5: 'library.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.ROOFTOP_AT_NIGHT,
    name: 'rooftop night',
    aliases: [],
    image: 'image_2023-03-05_02-52-41.webp',
    imageNiji5: 'school-rooftop-night.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CHEMISTRY_LAB,
    name: 'chemistry lab',
    aliases: ['chemistry class', 'chemistry room'],
    image: 'image_2023-03-05_03-27-06.webp',
    imageNiji5: 'classroom-chemistry.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SUPERNATURAL_CLUBROOM,
    name: 'supernatural class',
    aliases: ['supernatural club'],
    image: 'image_2023-03-05_04-03-23.webp',
    imageNiji5: 'supernatural-classroom.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.BEDROOM,
    name: 'bedroom',
    aliases: [],
    image: 'image_2023-03-05_04-09-50.webp',
    imageNiji5: 'bedroom.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BEDROOM_EVENING,
    name: 'bedroom evening',
    aliases: ['bedroom morning', 'bedroom evening'],
    image: 'bedroom-evening.webp',
    imageNiji5: 'bedroom-sunset.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BEDROOM_NIGHT,
    name: 'bedroom night',
    aliases: ['dark bedroom'],
    image: 'image_2023-03-05_04-09-50.webp',
    imageNiji5: 'bedroom-nighttime.webp',
    description: 'bedroom-nighttime.webp',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BEDROOM_CAMPUS,
    name: 'community bedroom',
    aliases: [],
    image: 'image_2023-03-07_04-36-40.webp',
    imageNiji5: 'bedroom-dorm.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BEDROOM_BOOKS,
    name: 'bedroom books',
    aliases: [],
    image: 'image_2023-03-08_18-36-07.webp',
    imageNiji5: 'bedroom-bookshelves.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BEDROOM_BOY,
    name: 'bedroom boy',
    aliases: [],
    image: 'bedroom-boy.webp',
    imageNiji5: 'bedroom-boy.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LIVINGROOM_DAY,
    name: 'livingroom',
    aliases: ['livingroom', 'apartment'],
    image: 'livingroom-day.webp',
    imageNiji5: 'livingroom-day.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LIVINGROOM_EVENING,
    name: 'livingroom sunset',
    aliases: ['livingroom morning', 'livingroom evening', 'apartment evening', 'apartment morning'],
    image: 'livingroom-day.webp',
    imageNiji5: 'livingroom-evening.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LIVINGROOM_NIGHT,
    name: 'livingroom night',
    aliases: ['apartment night'],
    image: 'image_2023-03-05_04-21-05.webp',
    imageNiji5: 'livingroom-night.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.JAPANESE_FESTIVAL,
    name: 'festival',
    aliases: [],
    image: 'image_2023-03-05_12-58-45.webp',
    imageNiji5: 'japanese-festival.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.DESERT,
    name: 'desert',
    aliases: [],
    image: 'image_2023-03-05_14-04-05.webp',
    imageNiji5: 'desert.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.FOREST,
    name: 'forest',
    aliases: [],
    image: 'image_2023-03-05_14-05-33.webp',
    imageNiji5: 'forest.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CLASSROOM_THUNDERSTORM,
    name: 'classroom evening',
    aliases: ['classroom storm'],
    image: '',
    imageNiji5: 'classroom-during-thunderstorm.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SCHOOL_BACKYARD,
    name: 'academy backyard',
    aliases: ['uni backyard', 'school backyard'],
    image: '',
    imageNiji5: 'backyard-school.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SCHOOL_CAMPUS,
    name: 'academy campus',
    aliases: ['academy grounds', 'campus', 'uni'],
    image: '',
    imageNiji5: 'school-campus.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SCHOOL_CAMPUS_NIGHT,
    name: 'academy night',
    aliases: ['campus night', 'uni night', 'school night'],
    image: 'image_2023-03-05_15-32-09.webp',
    imageNiji5: 'school-campus-night.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.COFFEE_SHOP,
    name: 'coffee shop',
    aliases: ['coffeeshop', 'starbucks'],
    image: 'image_2023-03-05_15-46-42.webp',
    imageNiji5: 'coffee-shop.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.HIKING_TRAIL,
    name: 'hiking',
    aliases: [],
    image: 'image_2023-03-05_15-55-51.webp',
    imageNiji5: 'hiking-trail.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CAMPING_SPOT,
    name: 'camping',
    aliases: ['camp spot', 'campsite'],
    image: 'image_2023-03-05_16-08-53.webp',
    imageNiji5: 'camping-spot-day.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CAMPING_SPOT_NIGHT,
    name: 'camping night',
    aliases: ['campsite night'],
    image: 'image_2023-03-05_16-08-53.webp',
    imageNiji5: 'camping-spot-night.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.SCHOOL_GYM,
    name: 'gym',
    aliases: [],
    image: 'image_2023-03-05_17-44-05.webp',
    imageNiji5: 'gym.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.DANCE_FLOOR_PARTY,
    name: 'dance floor',
    aliases: ['party'],
    image: 'image_2023-03-05_18-03-13.webp',
    imageNiji5: 'dance-floor-party.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.KITCHEN,
    name: 'kitchen',
    aliases: [],
    image: 'kitchen.webp',
    imageNiji5: 'kitchen.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.POOL,
    name: 'pool',
    aliases: [],
    image: 'image_2023-03-05_19-23-41.webp',
    imageNiji5: 'pool.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.BALLROOM,
    name: 'ballroom',
    aliases: [],
    image: 'image_2023-03-05_19-29-20.webp',
    imageNiji5: 'ballroom.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.CLUB_ROOM,
    name: 'club room',
    aliases: ['clubroom', 'school club', 'academy club'],
    image: 'image_2023-03-05_19-30-18.webp',
    imageNiji5: 'school-clubroom.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.CONCERT_VENUE,
    name: 'concert venue',
    aliases: ['concert hall', 'theater'],
    image: 'concert-venue.webp',
    imageNiji5: 'concert-venue.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.COMPUTER_LAB,
    name: 'computer lab',
    aliases: ['computer', 'coding class'],
    image: 'image_2023-03-05_20-51-14.webp',
    imageNiji5: 'computer-lab.webp',
    description: 'computer room in the school',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.TEACHERS_ROOM,
    name: 'professor room',
    aliases: ['teacher lounge', 'teacher office', 'professor office'],
    image: 'teachers-room.webp',
    imageNiji5: 'teacher-lounge.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.CORRIDOR,
    name: 'academy hallway',
    aliases: ['academy corridor', 'uni hallway', 'uni corridor', 'school hallway', 'school corridor'],
    image: 'image_2023-03-06_00-44-09.webp',
    imageNiji5: 'hallway.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.CLINIC,
    name: 'clinic',
    aliases: ['hospital', 'emergency room'],
    image: 'image_2023-03-06_00-44-15.webp',
    imageNiji5: 'clinic.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CAFE_AT_NIGHT_INSIDE,
    name: 'cafe night',
    aliases: [],
    image: 'cafe-at-night-inside.webp',
    imageNiji5: 'cafe-night.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.STADIUM,
    name: 'stadium',
    aliases: [],
    image: 'image_2023-03-06_00-48-59.webp',
    imageNiji5: 'stadium.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.VENDING_MACHINE_OUTSIDE,
    name: 'vending machine',
    aliases: [],
    image: 'image_2023-03-06_01-03-42.webp',
    imageNiji5: 'vending-machines-outside.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BIOLOGY_ROOM,
    name: 'biology room',
    aliases: ['biology class'],
    image: 'image_2023-03-06_04-21-00.webp',
    imageNiji5: 'classroom-biology.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.COOKING_CLASS,
    name: 'cooking class',
    aliases: ['cooking room', 'cooking school', 'cooking academy', 'culinary class', 'culinary room', 'culinary school', 'culinary academy'],
    image: 'image_2023-03-06_19-50-26.webp',
    imageNiji5: 'classroom-cooking.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.OFFICE,
    name: 'office',
    aliases: [],
    image: 'image_2023-03-06_19-51-39.webp',
    imageNiji5: 'office.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SCHOOL_COURTYARD,
    name: 'academy courtyard',
    aliases: ['college yard', 'uni yard', 'courtyard'],
    image: 'image_2023-03-06_19-58-51.webp',
    imageNiji5: 'school-courtyard.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SCHOOL_FRONT_GATE,
    name: 'academy gate',
    aliases: ['academy entrance', 'college entrance', 'college gate', 'uni entrance', 'uni gate', 'school entrance', 'school gate' ],
    image: 'image_2023-03-06_19-58-58.webp',
    imageNiji5: 'school-gate.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.CAFE_AT_NIGHT_OUTSIDE,
    name: 'street cafe night',
    aliases: [],
    image: 'image_2023-03-06_20-44-59.webp',
    imageNiji5: 'cafe-street-night.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.CANDLE_LIGHT_DATE,
    name: 'candle date',
    aliases: ['romantic date', 'restaurant'],
    image: 'candle-light-date.webp',
    imageNiji5: 'candle-date.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.POOL_PARTY,
    name: 'pool party',
    aliases: [],
    image: 'image_2023-03-06_22-14-49.webp',
    imageNiji5: 'pool-party.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.WAY_HOME_FROM_SCHOOL,
    name: 'neighborhood',
    aliases: ['way home'],
    image: 'image_2023-03-06_22-50-15.webp',
    imageNiji5: 'suburban-neighborhood.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.DRIVING_LESSONS,
    name: 'driving lessons',
    aliases: ['driving class', 'driving school'],
    image: 'image_2023-03-06_22-50-58.webp',
    imageNiji5: 'driving-lesson.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.TEA_SHOP,
    name: 'tea shop',
    aliases: [],
    image: 'image_2023-03-06_23-24-25.webp',
    imageNiji5: 'tea-shop.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.TEA_ROOM,
    name: 'tea room',
    aliases: ['tea club', 'yato tea'],
    image: 'image_2023-03-06_23-24-25.webp',
    imageNiji5: 'tea-room-new.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.AQUARIUM,
    name: 'aquarium',
    aliases: [],
    image: 'image_2023-03-06_23-33-20.webp',
    imageNiji5: 'aquarium.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.STREAMING_SETUP,
    name: 'stream setup',
    aliases: ['stream room'],
    image: 'image_2023-03-07_01-46-45.webp',
    imageNiji5: 'streaming-room.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.ONSEN,
    name: 'onsen',
    aliases: ['hot tub', 'spa'],
    image: 'image_2023-03-07_02-52-40.webp',
    imageNiji5: 'onsen-new.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.DORM_HALLWAY,
    name: 'dorm hallway',
    aliases: ['dorm corridor'],
    image: 'image_2023-03-08_14-29-41.webp',
    imageNiji5: 'hallway-dorm.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.SHOPPING_MALL,
    name: 'shopping mall',
    aliases: ['shopping center'],
    image: 'image_2023-03-08_14-43-52.webp',
    imageNiji5: 'shopping-mall-new.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BOWLING_ALLEY,
    name: 'bowling',
    aliases: [],
    image: 'image_2023-03-08_14-58-06.webp',
    imageNiji5: 'bowling-alley.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.ARCADE,
    name: 'arcade',
    aliases: [],
    image: 'image_2023-03-08_15-08-23.webp',
    imageNiji5: 'arcade-games.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.GREENHOUSE,
    name: 'greenhouse',
    aliases: [],
    image: 'image_2023-03-08_16-15-20.webp',
    imageNiji5: 'greenhouse.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.RAINFOREST,
    name: 'rainforest',
    aliases: ['tropical forest'],
    image: 'image_2023-03-08_16-16-30.webp',
    imageNiji5: 'rainforest.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.SMALL_TOWN_OUTDOOR,
    name: 'town',
    aliases: ['small city'],
    image: 'image_2023-03-08_16-26-27.webp',
    imageNiji5: 'small-town.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BIG_CITY,
    name: 'big city',
    aliases: ['metropolis'],
    image: 'image_2023-03-08_16-33-38.webp',
    imageNiji5: 'big-city.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.ISOLATED_ROOM,
    name: 'isolated room',
    aliases: ['hikkikomori'],
    image: 'isolated-room.webp',
    imageNiji5: 'isolated-room.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.RIVERBANK,
    name: 'river',
    aliases: ['shore'],
    image: 'image_2023-03-08_18-51-47.webp',
    imageNiji5: 'river.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.KARAOKE,
    name: 'karaoke',
    aliases: ['booth'],
    image: 'image_2023-03-08_23-46-08.webp',
    imageNiji5: 'karaoke-room.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.OTAKU_ROOM,
    name: 'otaku room',
    aliases: ['anime room'],
    image: 'image_2023-03-09_03-50-34.webp',
    imageNiji5: 'otaku-room.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.STAGE,
    name: 'stage',
    aliases: [],
    image: 'image_2023-03-11_12-38-28.webp',
    imageNiji5: 'stage-new.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LAKE_SHORE,
    name: 'lake',
    aliases: [],
    image: 'image_2023-03-11_12-38-37.webp',
    imageNiji5: 'park-lake.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BAR,
    name: 'bar',
    aliases: [],
    image: 'image_2023-03-11_12-49-17.webp',
    imageNiji5: 'bar.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.TOWN_OUTSKIRTS,
    name: 'outskirts',
    aliases: [],
    image: 'town-outskirts.jpg',
    imageNiji5: 'town-outskirts.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.NIGHT_CLUB,
    name: 'night club',
    aliases: ['nightclub'],
    image: 'image_2023-03-11_13-03-24.webp',
    imageNiji5: 'night-club.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.SMALL_STORE,
    name: 'store',
    aliases: [],
    image: 'in-store.jpg',
    imageNiji5: 'dollar-store.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.SUPERMARKET,
    name: 'supermarket',
    aliases: [],
    image: 'in-supermarket.webp',
    imageNiji5: 'supermarket.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.REMOTE_BEACH,
    name: 'beach',
    aliases: [],
    image: 'remote-beach.webp',
    imageNiji5: 'beach-remote.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.OCCULT_CLUB,
    name: 'occult club',
    aliases: ['occult room'],
    image: 'occult-club.webp',
    imageNiji5: 'occult-club.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SNOWY_LANDSCAPE,
    name: 'snow',
    aliases: ['winter'],
    image: 'snowy-landscape.webp',
    imageNiji5: 'snowy-landscape.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CREEPY_CLASSROOM,
    name: 'creepy class',
    aliases: [],
    image: 'creepy-classroom.webp',
    imageNiji5: 'classroom-creepy.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.HARBOR,
    name: 'harbor with ship',
    aliases: ['docks', 'ship'],
    image: 'harbor.webp',
    imageNiji5: 'harbor.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CREEPY_SUPERNATURAL_STREET,
    name: 'creepy street',
    aliases: ['supernatural street'],
    image: 'creepy-street.webp',
    imageNiji5: 'creepy-street.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CLASSROOM_RAIN,
    name: 'romantic class',
    aliases: ['class rain'],
    image: 'classroom-rain.webp',
    imageNiji5: 'classroom-rain.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SHRINE,
    name: 'shrine',
    aliases: [],
    image: 'shrine.webp',
    imageNiji5: 'shrine.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.TRAIN_STATION,
    name: 'station',
    aliases: [],
    image: 'train-station.webp',
    imageNiji5: 'train-station-new.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CINEMA,
    name: 'cinema',
    aliases: ['movie theater'],
    image: 'cinema.webp',
    imageNiji5: 'cinema.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CINEMA_HALL,
    name: 'outside movie',
    aliases: ['cinema lobby', 'theater lobby'],
    image: 'cinemahall.webp',
    imageNiji5: 'cinema-hall.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.GAMER_ROOM,
    name: 'gaming room',
    aliases: ['gaming setup'],
    image: 'gamerroom.webp',
    imageNiji5: 'streaming-gaming-room.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.SAKURA_HILL,
    name: 'sakura tree',
    aliases: ['hill'],
    image: 'sakura-tree-hill.webp',
    imageNiji5: 'sakura-hill.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.POND,
    name: 'pond',
    aliases: [],
    image: 'pond-park.webp',
    imageNiji5: 'pond.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.PLAYGROUND,
    name: 'playground',
    aliases: [],
    image: 'playground.webp',
    imageNiji5: 'playground.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.PARK_BENCH,
    name: 'park bench',
    aliases: [],
    image: 'park-bench.webp',
    imageNiji5: 'park-bench.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.MEADOW,
    name: 'meadow',
    aliases: [],
    image: 'meadow.webp',
    imageNiji5: 'meadow.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.MAGICAL_CAVE,
    name: 'magic cave',
    aliases: [],
    image: 'magical-cave.webp',
    imageNiji5: 'magical-cave.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CAVE,
    name: 'cave',
    aliases: [],
    image: 'cave.webp',
    imageNiji5: 'cave.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.FOUNTAIN,
    name: 'fountain',
    aliases: [],
    image: 'fountain.webp',
    imageNiji5: 'fountain.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.FUTURISTIC_CITY,
    name: 'futuristic city',
    aliases: ['future city'],
    image: 'futuristic-city.webp',
    imageNiji5: 'futuristic-city.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.FAST_FOOD_RESTAURANT,
    name: 'fast food',
    aliases: [],
    image: 'fast-food-restaurant.webp',
    imageNiji5: 'fast-food.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BALCONY,
    name: 'balcony',
    aliases: [],
    image: 'balcony.webp',
    imageNiji5: 'balcony.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.ZOO,
    name: 'zoo',
    aliases: [],
    image: 'zoo.jpg',
    imageNiji5: 'zoo.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.SPACESHIP,
    name: 'spaceship',
    aliases: [],
    image: 'spaceship.jpg',
    imageNiji5: 'spaceship.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.SCIENCE_LAB,
    name: 'science lab',
    aliases: ['laboratory', 'science class'],
    image: 'science-lab.jpg',
    imageNiji5: 'science-lab.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.RUINS,
    name: 'ruins',
    aliases: ['ruined'],
    image: 'ruins.jpg',
    imageNiji5: 'ruins.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.POLICE_STATION,
    name: 'police',
    aliases: [],
    image: 'police-station.jpg',
    imageNiji5: 'police-station.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.HIGHWAY,
    name: 'highway',
    aliases: ['road'],
    image: 'highway.jpg',
    imageNiji5: 'highway.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.GARDEN_RAIN,
    name: 'garden rain',
    aliases: [],
    image: 'garden-rain.jpg',
    imageNiji5: 'garden.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.GALAXY,
    name: 'galaxy',
    aliases: ['space', 'dream'],
    image: 'galaxy.jpg',
    imageNiji5: 'galaxy.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.ENGINEERING_WORKSHOP,
    name: 'engineering workshop',
    aliases: ['crafting workshop', 'workshop'],
    image: 'engineering-workshop.jpg',
    imageNiji5: 'engineering-workshop.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.SCHOOL_WC,
    name: 'wc',
    aliases: ['academy restroom', 'toilet', 'community restroom', 'toilet', 'school restroom', 'college restroom', 'uni restroom'],
    image: 'school-wc.jpg',
    imageNiji5: 'community-restroom.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.BUILDING,
    name: 'building',
    aliases: [],
    image: 'building.jpg',
    imageNiji5: 'building.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BATHROOM,
    name: 'bathroom',
    aliases: [],
    image: 'bathroom.jpg',
    imageNiji5: 'bathroom.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BACKYARD_DAY,
    name: 'backyard',
    aliases: [],
    image: 'backyard-day.jpg',
    imageNiji5: 'backyard-day.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BACKYARD_SUNSET,
    name: 'backyard sunset',
    aliases: ['backyard evening', 'backyard morning'],
    image: 'backyard-day.jpg',
    imageNiji5: 'backyard-evening.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BACKYARD_NIGHT,
    name: 'backyard night',
    aliases: [],
    image: 'backyard-night.jpg',
    imageNiji5: 'backyard-night.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.STORAGE_ROOM,
    name: 'storage',
    aliases: [],
    image: 'storage-room.jpg',
    imageNiji5: 'storage-room.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.PARK_FIELD,
    name: 'park field',
    aliases: ['dog park'],
    image: 'park-field.jpg',
    imageNiji5: 'park-field.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.ABANDONED_HOUSE,
    name: 'abandoned',
    aliases: [],
    image: 'abandoned-house.webp',
    imageNiji5: 'abandoned-house.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.RELIGIOUS_SPACE,
    name: 'relig',
    aliases: [],
    image: 'religious-space.webp',
    imageNiji5: '',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.JANITOR_CLOSET,
    name: 'janitor closet',
    aliases: ['janitor storage'],
    image: 'janitor-closet.jpg',
    imageNiji5: 'janitor-closet.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.PODCAST_ROOM,
    name: 'podcast room',
    aliases: ['recording studio'],
    image: 'podcast_room.webp',
    imageNiji5: 'podcast-room.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.AUDITORIUM,
    name: 'auditorium',
    aliases: [],
    image: '',
    imageNiji5: 'auditorium.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.BREAKROOM,
    name: 'break room',
    aliases: ['snack machine'],
    image: '',
    imageNiji5: 'breakroom.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.CHURCH,
    name: 'church',
    aliases: [],
    image: '',
    imageNiji5: 'church.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.CODING_ROOM,
    name: 'coding room',
    aliases: ['workspace', 'home office'],
    image: '',
    imageNiji5: 'coding-room.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CONFERENCE_ROOM,
    name: 'conference room',
    aliases: ['av room'],
    image: '',
    imageNiji5: 'conference-room.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.DARK_BASEMENT,
    name: 'creepy basement',
    aliases: ['dark basement', 'old basement'],
    image: '',
    imageNiji5: 'dark-basement.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.DRESSING_ROOM,
    name: 'dressing room',
    aliases: ['changing room', 'backstage'],
    image: '',
    imageNiji5: 'dressing-room.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.FOREST_PREHISTORIC,
    name: 'prehistoric forest',
    aliases: [],
    image: '',
    imageNiji5: 'forest-prehistoric.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.GAS_STATION,
    name: 'gas station',
    aliases: [],
    image: '',
    imageNiji5: 'gas-station.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.KITCHEN_INDUSTRIAL,
    name: 'industrial kitchen',
    aliases: [],
    image: '',
    imageNiji5: 'kitchen-industrial.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.MEDIEVAL_ROOM,
    name: 'medieval room',
    aliases: ['medieval', 'castle'],
    image: '',
    imageNiji5: 'medieval-room.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.NURSE_OFFICE,
    name: 'nurse office',
    aliases: ['nurse'],
    image: '',
    imageNiji5: 'nurse-office.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.OUTSIDE,
    name: 'outside',
    aliases: [],
    image: '',
    imageNiji5: 'outside.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.PARKING_LOT,
    name: 'parking lot',
    aliases: ['parking'],
    image: '',
    imageNiji5: 'parking-lot.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.POSTAPOCALYPTIC,
    name: 'apocalyptic',
    aliases: ['apocalypsis'],
    image: '',
    imageNiji5: 'postapocalyptic.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.SCHOOL_KITCHEN,
    name: 'school kitchen',
    aliases: [],
    image: '',
    imageNiji5: 'kitchen-school.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SCRAPYARD,
    name: 'scrapyard',
    aliases: ['junkyard'],
    image: 'teenager-hideout-spot.webp',
    imageNiji5: 'scrap-yard.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.SKY,
    name: 'sky',
    aliases: ['narrator', 'narration', 'the end', 'ending'],
    image: 'sky.webp',
    imageNiji5: 'sky.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.UNDERWATER,
    name: 'underwater',
    aliases: ['ocean'],
    image: '',
    imageNiji5: 'underwater.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.ANIMAL_SHELTER,
    name: 'animal shelter',
    aliases: ['dog shelter'],
    image: '',
    imageNiji5: 'animal-shelter.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CLASSROOM_SAKURA,
    name: 'sakura class',
    aliases: [],
    image: '',
    imageNiji5: 'classroom-sakura.jpg',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.BEDROOM_KIRINO,
    name: 'kirino bedroom',
    aliases: ['messy bedroom'],
    image: '',
    imageNiji5: 'kirino-bedroom.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.ROOFTOP_LUNCH_AREA_SAKURA,
    name: 'sakura roof lunch',
    aliases: ['roof cafe'],
    image: '',
    imageNiji5: 'rooftop-lunch-sakura.jpg',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.ROOFTOP_SAKURA,
    name: 'sakura roof',
    aliases: [],
    image: '',
    imageNiji5: 'rooftop-sakura.jpg',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.COURTYARD_SAKURA,
    name: 'sakura courtyard',
    aliases: [],
    image: '',
    imageNiji5: 'sakura-courtyard.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.HALLWAY_SAKURA,
    name: 'sakura hallway',
    aliases: ['sakura corridor'],
    image: '',
    imageNiji5: 'sakura-hallway.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.LIBRARY_SAKURA,
    name: 'sakura library',
    aliases: [],
    image: '',
    imageNiji5: 'sakura-library.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SCHOOL_CAMPUS_SAKURA,
    name: 'sakura campus',
    aliases: ['sakura grounds'],
    image: '',
    imageNiji5: 'sakura-school-campus.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SCHOOL_GATE_SAKURA,
    name: 'sakura gate',
    aliases: ['sakura entrance'],
    image: '',
    imageNiji5: 'sakura-school-gate.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.LIBRARY_SCHOOL,
    name: 'academy library',
    aliases: ['college library', 'school library', 'uni library'],
    image: '',
    imageNiji5: 'school-library.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.YATO_CLASSROOM,
    name: 'yato class',
    aliases: [],
    image: '',
    imageNiji5: 'classroom-yato.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.AMUSEMENT_PARK,
    name: 'amusement park',
    aliases: [],
    image: '',
    imageNiji5: 'amusement-park.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BEDROOM_AYAMI,
    name: 'ayami bedroom',
    aliases: [],
    image: '',
    imageNiji5: 'ayami-bedroom.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LIVINGROOM_AYAMI,
    name: 'ayami livingroom',
    aliases: ['ayami living room', 'ayami home', 'ayami apartment'],
    image: '',
    imageNiji5: 'ayami-livingroom.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.GARDEN,
    name: 'garden',
    aliases: [],
    image: '',
    imageNiji5: 'garden.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.BEDROOM_KAYA,
    name: 'kaya bedroom',
    aliases: [],
    image: '',
    imageNiji5: 'kaya-bedroom.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LIVINGROOM_KAYA,
    name: 'kaya livingroom',
    aliases: ['kaya living room', 'kaya home', 'kaya apartment'],
    image: '',
    imageNiji5: 'kaya-livingroom.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LIVINGROOM_KIRINO,
    name: 'kirino livingroom',
    aliases: ['kirino living room', 'kirino home', 'kirino apartment'],
    image: '',
    imageNiji5: 'kirino-livingroom.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LIVINGROOM_LEMON,
    name: 'john livingroom',
    aliases: ['john living room', 'john home', 'john apartment'],
    image: '',
    imageNiji5: 'lemon-appartment.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LIVINGROOM_MIKIKO,
    name: 'mikiko livingroom',
    aliases: ['mikiko living room', 'mikiko home', 'mikiko apartment'],
    image: '',
    imageNiji5: 'mikiko-livingroom.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CLASSROOM_OCCULT,
    name: 'occult class',
    aliases: [],
    image: '',
    imageNiji5: 'occult-classroom.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.HALLWAY_OCCULT,
    name: 'occult hallway',
    aliases: ['occult corridor'],
    image: '',
    imageNiji5: 'occult-hallway.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.HOUSE_OCCULT,
    name: 'occult house',
    aliases: ['occult hide'],
    image: '',
    imageNiji5: 'occult-house.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.KITCHEN_OCCULT,
    name: 'occult kitchen',
    aliases: [],
    image: '',
    imageNiji5: 'occult-kitchen.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.MEETING_OCCULT,
    name: 'occult meeting',
    aliases: [],
    image: '',
    imageNiji5: 'occult-meeting.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.ROOFTOP_OCCULT,
    name: 'occult roof',
    aliases: [],
    image: '',
    imageNiji5: 'occult-rooftop.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.BEDROOM_ORIGAMI,
    name: 'origami bedroom',
    aliases: [],
    image: '',
    imageNiji5: 'origami-bedroom.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LIVINGROOM_ORIGAMI,
    name: 'origami livingroom',
    aliases: [],
    image: '',
    imageNiji5: 'origami-livingroom.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.ROOFTOP_LUNCH_AREA,
    name: 'rooftop lunch area',
    aliases: [],
    image: '',
    imageNiji5: 'rooftop-lunch-area.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.GARDEN_SAKURA,
    name: 'sakura garden',
    aliases: [],
    image: '',
    imageNiji5: 'sakura-garden.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.GARDEN_SCHOOL,
    name: 'academy garden',
    aliases: ['school garden', 'college garden', 'uni garden'],
    image: '',
    imageNiji5: 'school-garden.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.KITCHEN_SAKURA,
    name: 'sakura kitchen',
    aliases: [],
    image: '',
    imageNiji5: 'sakura-kitchen.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.PARK_SAKURA,
    name: 'sakura park',
    aliases: [],
    image: '',
    imageNiji5: 'sakura-park.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.ANIMAL_SHELTER_SAKURA,
    name: 'sakura shelter',
    aliases: [],
    image: '',
    imageNiji5: 'sakura-shelter.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SCIENCE_CLASSROOM,
    name: 'science class',
    aliases: [],
    image: '',
    imageNiji5: 'classroom-science.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SECRET_ROOM,
    name: 'secret room',
    aliases: [],
    image: '',
    imageNiji5: 'secret-room.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.TOWN_SQUARE,
    name: 'town square',
    aliases: ['city square', 'city center', 'town center'],
    image: '',
    imageNiji5: 'town-square.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.SCHOOL_CAMPUS_MAGIC,
    name: 'magic academy',
    aliases: ['occult academy', 'magic school', 'occult school', 'magic grounds', 'magic campus'],
    image: '',
    imageNiji5: 'magic-school-campus.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.DRAGON_LAIR,
    name: 'lair',
    aliases: ['chamber'],
    image: '',
    imageNiji5: 'dragon-lair.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.KITCHEN_GIRL,
    name: 'girl kitchen',
    aliases: ['ayami kitchen', 'origami kitchen', 'kirino kitchen', 'kaya kitchen'],
    image: '',
    imageNiji5: 'kitchen-girl.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LEMON_COOKING_SCHOOL,
    name: 'lemon cook',
    aliases: ['lemon culinary'],
    image: '',
    imageNiji5: 'lemon-cooking-school.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.MAID_CAFE,
    name: 'maid cafe',
    aliases: [],
    image: '',
    imageNiji5: 'maid-cafe.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.LIBRARY_OCCULT,
    name: 'occult library',
    aliases: [],
    image: '',
    imageNiji5: 'occult-library.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.CLUB_ROOM_SAKURA,
    name: 'sakura club',
    aliases: [],
    image: '',
    imageNiji5: 'sakura-clubroom.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.COMPUTER_LAB_SAKURA,
    name: 'sakura computer',
    aliases: [],
    image: '',
    imageNiji5: 'sakura-computer-lab.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.MUSICROOM_SAKURA,
    name: 'sakura music',
    aliases: [],
    image: '',
    imageNiji5: 'sakura-music-room.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.OCCULT_CLUB_SAKURA,
    name: 'sakura occult club',
    aliases: [],
    image: '',
    imageNiji5: 'sakura occult club.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.OFFICE_SAKURA,
    name: 'sakura office',
    aliases: [],
    image: '',
    imageNiji5: 'sakura-office.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.TEACHERS_ROOM_SAKURA,
    name: 'sakura teacher lounge',
    aliases: [],
    image: '',
    imageNiji5: 'sakura-teacher-lounge.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.SCIENCE_LAB_SAKURA,
    name: 'sakura science',
    aliases: ['sakura chemistry'],
    image: '',
    imageNiji5: 'sakura-science.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.GYM_SAKURA,
    name: 'sakura gym',
    aliases: [],
    image: '',
    imageNiji5: 'sakura-gym.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.CATACOMBS,
    name: 'catacombs',
    aliases: [],
    image: 'catacombs.webp',
    imageNiji5: 'catacombs.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.JAIL,
    name: 'jail',
    aliases: ['prison'],
    image: 'jail.webp',
    imageNiji5: 'jail.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.SEWERS,
    name: 'sewers',
    aliases: ['sewer'],
    image: 'sewers.webp',
    imageNiji5: 'sewers.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.ABANDONED_ALLEY,
    name: 'abandoned alley',
    aliases: ['abandoned street', 'abandoned town', 'abandoned city'],
    image: 'abandoned-alley.webp',
    imageNiji5: 'abandoned-alley.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.QUARRY,
    name: 'quarry',
    aliases: ['mine'],
    image: 'quarry.webp',
    imageNiji5: 'quarry.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.AIRPORT,
    name: 'airport',
    aliases: [],
    image: 'airport.webp',
    imageNiji5: 'airport.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.PARK_EVENING,
    name: 'park evening',
    aliases: ['park dawn', 'park sunrise', 'park sunset'],
    image: 'park-evening.webp',
    imageNiji5: 'park-evening.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.PARK_NIGHT,
    name: 'park night',
    aliases: [],
    image: 'park-night.webp',
    imageNiji5: 'park-night.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.CAMPUS_CAFERETIA,
    name: 'campus cafeteria',
    aliases: ['campus cafe'],
    image: '',
    imageNiji5: 'campus-cafeteria.webp',
    description: '',
    clothingStyle: ClothingStyle.SCHOOL,
  },
  {
    id: SceneId.COURTROOM,
    name: 'courtroom',
    aliases: ['court '],
    image: '',
    imageNiji5: 'courtroom.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.DORM_EVENING,
    name: 'dorm evening',
    aliases: [],
    image: '',
    imageNiji5: 'dorm-evening.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.DORM,
    name: 'dorm',
    aliases: [],
    image: '',
    imageNiji5: 'dorm-evening.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.FASHION_STUDIO,
    name: 'fashion studio',
    aliases: ['boutique', 'fashion store'],
    image: '',
    imageNiji5: 'fashion-studio.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.HIDEOUT,
    name: 'gang hideout',
    aliases: ['mikiko hideout', 'hideout'],
    image: '',
    imageNiji5: 'hideout.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.MANSION,
    name: 'mansion',
    aliases: [],
    image: '',
    imageNiji5: 'mansion.webp',
    description: '',
    clothingStyle: ClothingStyle.DATE,
  },
  {
    id: SceneId.PET_STORE,
    name: 'pet store',
    aliases: ['zoo store'],
    image: '',
    imageNiji5: 'pet-store.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.VILLAGE,
    name: 'village',
    aliases: ['countryside'],
    image: '',
    imageNiji5: 'village.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
  {
    id: SceneId.VOID,
    name: 'void',
    aliases: ['abyss', 'portal'],
    image: '',
    imageNiji5: 'void.webp',
    description: '',
    clothingStyle: ClothingStyle.CASUAL,
  },
]