<template>
  <TransitionGroup
    name="list"
    tag="div"
    class="poll-display-poll-items"
  >
    <div
      v-if="winnerChoice"
      key="winningChoice"
      class="poll-display-generating-episode"
    >
      <v-icon
        icon="mdi-loading"
        class="mdi-spin"
      /> {{ statusText }}...
    </div>
    <IcPollChoice
      v-for="(choice, idx) in displayedChoices"
      :key="choice.choice.letter || idx"
      :choice="choice"
      :total-votes="totalVotes"
    />
  </TransitionGroup>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { PollChoice, TwitchPollChoice } from '../../../../common/src/Types'
import IcPollChoice from './IcPollChoice.vue'

const props = defineProps<{
  choices: { choice: PollChoice, twitchChoice: TwitchPollChoice }[],
  winnerChoice: PollChoice | null,
  statusText: string,
}>()

const displayedChoices = computed(() => {
  if (!props.winnerChoice) {
    return props.choices
  }
  return props.choices.filter((c) => c.choice.letter === props.winnerChoice?.letter)
})

const totalVotes = computed(() => {
  return props.choices.reduce((prev, val) => prev + val.twitchChoice.totalVotes, 0)
})
</script>
