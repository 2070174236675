import * as VueRouter from 'vue-router'
import * as Vue from 'vue'
import App from './App.vue'
import draggable from 'vuedraggable'

import IndexView from './views/IndexView.vue'
import EpisodesView from './views/EpisodesView.vue'
import LoginView from './views/LoginView.vue'
import VnView from './views/VnView.vue'
import ControlView from './views/ControlView.vue'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import user from './user'
import { init } from './toast'

import { init as initErrorHandler } from './error-handler'

(async () => {
  initErrorHandler()

  const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes: [
      { name: 'index', path: '/', component: IndexView, meta: { title: 'Infinite Chronicles', protected: true } },
      { name: 'episodes', path: '/episodes', component: EpisodesView, meta: { title: 'Infinite Chronicles Episodes' } },
      { name: 'login', path: '/login', component: LoginView, meta: { title: 'Login' } },
      { name: 'vn', path: '/vn', component: VnView, meta: { title: 'Infinite Chronicles VN', protected: true } },
      { name: 'control', path: '/control', component: ControlView, meta: { title: 'Infinite Chronicles Control Panel', protected: true } },
    ],
  })

  await user.init()

  router.beforeEach(async (to, from, next) => {
    if (to.meta.protected) {
      if (!user.getMe()) {
        next({ name: 'login' })
        return
      }
    } else {
      if (from.name === 'login' && user.getMe()) {
        next({ name: 'index' })
        return
      }
    }

    if (from.name) {
      document.documentElement.classList.remove(`view-${String(from.name)}`)
    }
    document.documentElement.classList.add(`view-${String(to.name)}`)

    document.title = `${to.meta.title}`
    next()
  })

  const vuetify = createVuetify({
    components,
    directives,
    theme: {
      defaultTheme: 'dark',
      themes: {
        dark: {
          colors: {
            primary: `#FCFFF0`,
            secondary: `#C586AE`,
          },
        },
      },
    },
  })

  const app = Vue.createApp(App)
  app.use(router)
  app.use(vuetify)
  init(app)
  app.component('VueDraggable', draggable)
  app.mount('#app')
})()
