export enum PromptType {
  TWITCH_REDEMPTION = 'twitch_redemption',
  TWITCH_SEQUEL_REDEMPTION = 'twitch_sequel_redemption',
  TWITCH_COMMAND = 'twitch_command',
  TWITCH_SEQUEL_COMMAND = 'twitch_sequel_command',
  TWITCH_POLL = 'twitch_poll',
}

export interface NewPromptDef {
  topic: string
  displayname: string
  usercolor: string
  type: PromptType
  poll_id: number
  status: PromptStatus
  prequel_episode_id: number | null
}

export enum PromptStatus {
  QUEUED = 'queued',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
  FLAGGED = 'flagged',
}
