<template>
  <div>
    <div
      class="episode-background"
      :style="backgroundStyle"
    />
    <div
      v-if="nextScene && transitioning !== -1"
      class="episode-background episode-background-next"
      :style="nextBackgroundStyle"
    />
    <MagicDust v-if="magicDust" />
    <!-- preloading possibly needed graphics: -->
    <div
      v-if="nextScene"
      :style="nextBackgroundStyle"
    />
    <div
      :style="{ backgroundImage: `url(/assets/scenes/${scene.image})` }"
    />
    <div
      v-if="scene.imageNiji5"
      :style="{ backgroundImage: `url(/assets/scenes-5/${scene.imageNiji5})` }"
    />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import MagicDust from './EpisodePlayer/MagicDust.vue'
import { ModifierName, ModifierRow, SceneDef } from '../../../common/src/Types'

const props = withDefaults(defineProps<{
  modifiers: ModifierRow[]
  scene: SceneDef
  nextScene?: SceneDef | null
  transitioning?: number
  magicDust?: boolean
}>(), {
  nextScene: null,
  transitioning: -1,
  magicDust: true,
})

const sceneImage = (scene: SceneDef): string => {
  if (
    scene.imageNiji5
    && props.modifiers.some(m => m.name === ModifierName.NIJI_5_BG && m.value > 0)
  ) {
    return `url(/assets/scenes-5/${scene.imageNiji5})`
  }
  return `url(/assets/scenes/${scene.image})`
}

const backgroundStyle = computed(() => {
  return { backgroundImage: sceneImage(props.scene) }
})

const transitioningVal = computed(() => {
  return props.transitioning
})

const OFFSET = 8
const CENTER_FACTOR = 100 + 2 * OFFSET

const nextBackgroundStyle = computed(() => {
  if (!props.nextScene) {
    return undefined
  }

  const percentage = (100 - transitioningVal.value) / 100
  const center = percentage * CENTER_FACTOR - OFFSET
  const startLeft = center - 100
  const endRight = center + 100
  const offLeft = center - OFFSET
  const offRight = center + OFFSET

  return {
    backgroundImage: sceneImage(props.nextScene),
    '-webkit-mask-image': `linear-gradient(270deg, rgba(0,0,0,0) ${startLeft}%, rgba(0,0,0,0) ${offLeft}%, rgba(0,0,0,1) ${offRight}%, rgba(0,0,0,1) ${endRight}%)`,
  }
})
</script>
