<template>
  <Transition>
    <PollDisplay
      v-if="queueInfo?.pollProcessing"
      ref="pollDisplay"
      class="poll-display"
      :poll="queueInfo.pollProcessing"
      :info="queueInfo"
    />
  </Transition>
  <Transition>
    <EpisodePlayer
      v-if="!queueInfo?.pollProcessing && currentEpisode && episodeActive"
      class="episode-player"
      :episode="currentEpisode"
      :next-episode="nextEpisode"
      :info="queueInfo"
      @started="onStarted"
      @ended="onEnded"
    />
  </Transition>
</template>
<script setup lang="ts">
import { computed, onMounted, onBeforeUnmount, ref, Ref, watch } from 'vue'
import { QueueInfoData, WsPath } from '../../../common/src/Types'
import { EpisodesRow } from '../EpisodesRow'
import EpisodePlayer from '../components/EpisodePlayer.vue'
import PollDisplay from '../components/PollDisplay.vue'
import { BgmPlayer } from '../BgmPlayer'
import { useRouter } from 'vue-router'
import { CommunicationWs } from '../CommunicationWs'

const router = useRouter()

const queueInfo = ref<QueueInfoData | null>(null)
const currentEpisode = ref<EpisodesRow | null>(null)
const nextEpisode = ref<EpisodesRow | null>(null)
const episodeActive = ref<boolean>(true)

const comm = new CommunicationWs()
const bgmPlayer = new BgmPlayer()

const pollDisplay = ref<InstanceType<typeof PollDisplay>>() as Ref<InstanceType<typeof PollDisplay>>

const bgmMode = ref<string>('init')
const bgmSrc = computed((): string => {
  switch (bgmMode.value) {
    case 'poll': return '/assets/audio/boba-date_2.mp3'
    case 'episode': return '/assets/audio/tavern.mp3'
    default: return ''
  }
})

const FADE_TIME_MS = 1250
let shouldReload = false
let version = ''

const maybeReload = () => {
  // chance to reload here!
  if (shouldReload) {
    console.log('reloading page')
    router.go(0)
    return
  }
}

const transitionPollToEpisode = (info: QueueInfoData, fadeTimeMs: number) => {
  if (!queueInfo.value) {
    // should not arrive here, because it is checked in onInfoCb already
    return
  }

  // poll is still doing some speaking, wait until finished
  if (pollDisplay.value.isSpeaking()) {
    pollDisplay.value.stopSpeaking()
    return
  }

  // we have stopped generating poll. next is to play an episode again
  bgmMode.value = 'episode'
  queueInfo.value.pollProcessing = null

  setTimeout(() => {
    queueInfo.value = info
    currentEpisode.value = queueInfo.value.episodes[0]
    if (queueInfo.value.episodes[1]) {
      nextEpisode.value = queueInfo.value.episodes[1]
    }
    episodeActive.value = true
  }, fadeTimeMs)
}

const transitionEpisodeToPoll = (info: QueueInfoData, fadeTimeMs: number) => {
  episodeActive.value = false
  bgmMode.value = 'poll'
  setTimeout(() => {
    queueInfo.value = info
    currentEpisode.value = null
    nextEpisode.value = null
  }, fadeTimeMs)
}

const transitionEpisodeToEpisode = (info: QueueInfoData, fadeTimeMs: number) => {
  if (!currentEpisode.value) {
    currentEpisode.value = info.episodes[0]
    return
  }

  episodeActive.value = false
  setTimeout(() => {
    currentEpisode.value = info.episodes[0]
    episodeActive.value = true
  }, fadeTimeMs)
}

const transition = (
  info: QueueInfoData,
  transitionFn: (info: QueueInfoData, fadeTimeMs: number) => void,
): void => {
  maybeReload()
  transitionFn(info, FADE_TIME_MS)
}

const onInfoCbDuringPoll = (info: QueueInfoData) => {
  if (bgmMode.value === 'init') {
    bgmMode.value = 'poll'
  }

  if (!info.pollProcessing) {
    transition(info, transitionPollToEpisode)
    return
  }

  // we are STILL processing a poll
  queueInfo.value = info
}

const onInfoCbDuringEpisode = (info:QueueInfoData) => {
  if (bgmMode.value === 'init') {
    bgmMode.value = 'episode'
  }

  if (info.pollProcessing) {
    transition(info, transitionEpisodeToPoll)
    return
  }

  queueInfo.value = info
  if (currentEpisode.value?.id !== info.episodes[0]?.id) {
    transition(info, transitionEpisodeToEpisode)
  }
  // next episode changed, this can happen without the current one changing
  // if something is added/removed in the episdoes playlist
  if (nextEpisode.value?.id !== info.episodes[1]?.id) {
    nextEpisode.value = info.episodes[1]
  }
}

const onInfoCb = (info: QueueInfoData) => {
  if (!version) {
    version = info.version
  } else if (version !== info.version) {
    shouldReload = true
  }

  if (queueInfo.value?.pollProcessing) {
    // we are currently processing a poll
    onInfoCbDuringPoll(info)
  } else {
    // we are currently playing an episode
    onInfoCbDuringEpisode(info)
  }
}

const transitionBgm = async (bgmSrc: string) => {
  await bgmPlayer.fadeOut()
  if (bgmSrc) {
    await bgmPlayer.fadeIn(bgmSrc)
  }
}

const onStarted = async (ep: EpisodesRow) => {
  await comm.episodeStarted(ep)
}

const onEnded = async (ep: EpisodesRow) => {
  await comm.episodeEnded(ep)
}

watch(bgmSrc, (newVal) => {
  transitionBgm(newVal)
})

onMounted(async () => {
  comm.onInfo(onInfoCb)
  transitionBgm(bgmSrc.value)
  await comm.start(WsPath.VN)
})

onBeforeUnmount(() => {
  bgmPlayer.cleanup()
  comm.stop()
})
</script>
<style>
.v-move,
.v-enter-active,
.v-leave-active {
  transition: opacity .5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
