<template>
  <v-container>
    <v-text-field v-model="name" />
    <v-text-field
      v-model="pass"
      type="password"
      @keydown.enter.prevent="login"
    />
    <v-btn @click="login">
      Login
    </v-btn>
  </v-container>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import user from '../user'
const name = ref<string>('')
const pass = ref<string>('')

const router = useRouter()
const login = async () => {
  const success = await user.login(name.value, pass.value)
  if (success) {
    router.go(0)
  }
}
</script>
