<template>
  <div class="poll-display-poll-item">
    <div class="poll-display-choice-title">
      <div
        class="poll-display-progress"
        :style="choiceStyle"
      />
      <div class="d-flex flex-row">
        <div class="poll-display-poll-item-letter">
          {{ choice.choice.letter }}
        </div>
        <img
          v-for="(ch, idx) in characters"
          :key="idx"
          :src="`/assets/character-icons/${ch.id}.png`"
          class="poll-display-character-icon ml-2"
        >
      </div>
      <div class="poll-display-poll-item-votes">
        {{ votes }} {{ votes === 1 ? 'vote' : 'votes' }}
      </div>
    </div>
    <AutoSizeText
      class="poll-display-poll-item-text"
      :text="choice.choice.text"
    />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { PollChoice, TwitchPollChoice } from '../../../../common/src/Types'
import { CharacterDef, CHARACTERS, findMentionedCharacters } from '../../../../common/src/Characters'
import AutoSizeText from '../AutoSizeText.vue'

const props = defineProps<{
  choice: { choice: PollChoice, twitchChoice: TwitchPollChoice },
  totalVotes: number
}>()

const votes = computed(() => props.choice.twitchChoice.totalVotes)

const characters = computed((): CharacterDef[] => findMentionedCharacters(CHARACTERS, props.choice.choice.text))

const choiceStyle = computed(() => {
  const p = props.totalVotes ? votes.value / props.totalVotes * 100 : 0
  return { width: `${p}%` }
})
</script>
