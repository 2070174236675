import { getAudioContext, loadAudioBuffer } from './audio'
import PitchShifter from './soundtouch/PitchShifter'

export class TtsPlayer {
  private shifter: PitchShifter | null = null

  private queue: { text: string, emotion: string, voicefile: string }[] = []
  constructor() {
    // pass
  }

  public setPitch(val: number): void {
    if (this.shifter) {
      this.shifter.pitch = val
    }
  }

  public setTempo(val: number): void {
    if (this.shifter) {
      this.shifter.tempo = val
    }
  }

  public queueFiles(...files: { text: string, emotion: string, voicefile: string }[]): void {
    this.queue.push(...files)
  }

  public getNextFile(): { text: string, emotion: string, voicefile: string } | null {
    return this.queue.shift() || null
  }

  public async playFile(file: string, pitch: number, tempo: number): Promise<void> {
    const audioBuffer: AudioBuffer = await loadAudioBuffer(file)
    await this.playBuffer(audioBuffer, pitch, tempo)
  }

  public async playBuffer(audioBuffer: AudioBuffer, pitch: number, tempo: number): Promise<void> {
    this._cleanupShifter()
    await this._playBuffer(audioBuffer, getAudioContext(), pitch, tempo)
    this._cleanupShifter()
  }

  private async _playBuffer(
    audioBuffer: AudioBuffer,
    audioContext: AudioContext,
    pitch: number,
    tempo: number,
  ): Promise<void> {
    return new Promise((resolve) => {
      this.shifter = new PitchShifter(audioContext, audioBuffer, 8192, resolve)
      this.shifter.pitch = pitch
      this.shifter.tempo = tempo
      this.shifter.connect(audioContext.destination)
    })
  }

  public cleanup(): void {
    this._drainQueue()
    this._cleanupShifter()
  }

  private _drainQueue(): void {
    do {
      this.queue.shift()
    } while (this.queue.length > 0)
  }

  private _cleanupShifter(): void {
    if (this.shifter) {
      this.shifter.disconnect()
      this.shifter = null
    }
  }
}
