import { CharacterDef, CharacterId, FALLBACK_VOICE_COQUI, NARRATOR_VOICE_COQUI, ORIGAMI_HYPER_TEMPO, YATO_DEMON_PITCH } from '../../common/src/Characters'
import { getModifierValue, ModifierName, ModifierRow } from '../../common/src/Types'
import { VnPlayerLine } from './Types'

let audioContext: AudioContext | null

export const getAudioContext = () => {
  if (!audioContext) {
    audioContext =  new AudioContext()
  }
  return audioContext
}

export const loadAudioBuffer = async (src: string): Promise<AudioBuffer> => {
  const res = await fetch(src)
  const arrayBuffer = await res.arrayBuffer()
  return await getAudioContext().decodeAudioData(arrayBuffer)
}

export const loadAudioBuffers = async (sources: string[]): Promise<Record<string, AudioBuffer>> => {
  const buffers: Record<string, AudioBuffer> = {}
  for (const src of sources) {
    try {
      buffers[src] = await loadAudioBuffer(src)
    } catch (e) {
      // pass
    }
  }
  return buffers
}

const getPitchOffsetValue = (pitchModifierValue: number): number => {
  const clamped = Math.max(-5, Math.min(5, pitchModifierValue))
  switch (clamped) {
    case -5: return -0.5
    case -4: return -0.4
    case -3: return -0.3
    case -2: return -0.2
    case -1: return -0.1
    case 0: return 0
    case 1: return 0.2
    case 2: return 0.4
    case 3: return 0.6
    case 4: return 0.8
    case 5: return 1
  }
  return 0
}

const yatoDemonMode = (modifiers: ModifierRow[]) => getModifierValue(modifiers, ModifierName.YATO_DEMON)
const origamiHyperMode = (modifiers: ModifierRow[]) => getModifierValue(modifiers, ModifierName.ORIGAMI_HYPER)
const pitchMode = (modifiers: ModifierRow[]) => getModifierValue(modifiers, ModifierName.PITCH)

export const determinePitch = (modifiers: ModifierRow[], character: CharacterDef | null, line: VnPlayerLine | null): number => {
  if (character?.id === CharacterId.YATO && yatoDemonMode(modifiers)) {
    return YATO_DEMON_PITCH
  }
  if (!character && !line?.actor) {
    return NARRATOR_VOICE_COQUI.pitch
  }
  return (character?.coquiVoice.pitch || FALLBACK_VOICE_COQUI.pitch) + getPitchOffsetValue(pitchMode(modifiers))
}

export const determineTempo = (modifiers: ModifierRow[], character: CharacterDef | null, line: VnPlayerLine | null): number => {
  if (character?.id === CharacterId.ORIGAMI && origamiHyperMode(modifiers)) {
    return ORIGAMI_HYPER_TEMPO
  }
  if (!character && !line?.actor) {
    return NARRATOR_VOICE_COQUI.speed
  }
  return character?.coquiVoice.speed || FALLBACK_VOICE_COQUI.speed
}
