<template>
  <div
    v-if="info"
    class="visual-container prompt-info-container"
  >
    <Transition
      name="fade"
      mode="out-in"
    >
      <PromptHighscore
        v-if="highscoreKey"
        :highscore="info.promptHighschore[highscoreKey]"
      />
    </Transition>
  </div>
</template>
<script setup lang="ts">
import { onBeforeUnmount, ref } from 'vue'
import { QueueInfoData } from '../../../../common/src/Types'
import PromptHighscore from './PromptHighscore.vue'

defineProps<{
  info: QueueInfoData | null,
}>()

const highscoreKey = ref<null | 'today' | 'month' | 'alltime'>('today')

let interval: any = null
const update = () => {
  let nextKey: 'today' | 'month' | 'alltime'
  if (highscoreKey.value === 'today') {
    nextKey = 'month'
  } else if (highscoreKey.value === 'month') {
    nextKey = 'alltime'
  } else {
    nextKey = 'today'
  }
  highscoreKey.value = null
  setTimeout(() => {
    highscoreKey.value = nextKey
  }, 50)
}
const startInterval = () => {
  if (interval) {
    return
  }
  interval = setInterval(update, 10000)
}
const endInterval = () => {
  if (interval) {
    clearInterval(interval)
  }
}

onBeforeUnmount(() => {
  endInterval()
})

startInterval()
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
