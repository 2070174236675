<template>
  <div class="poll-display-fanart">
    <div
      class="poll-display-fanart-image"
      :style="fanartStyle"
    >
        &nbsp;
    </div>
    <div
      v-for="(item, idx) in items"
      :key="idx"
      class="poll-display-fanart-image-next"
      :style="{backgroundImage: `url(${item.url})`}"
    >
        &nbsp;
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { FanartRow } from '../../../common/src/Types'
const props = defineProps<{
  items: FanartRow[],
}>()

const FANART_DISPLAY_DURATION_MS = 10000

const fanartIdx = ref<number>(0)
const fanartStyle = computed(() => {
  if (!props.items[fanartIdx.value]) {
    return undefined
  }
  return {
    backgroundImage: `url(${props.items[fanartIdx.value].url})`,
  }
})

let fanartTimeout: any = null
const nextFanart = () => {
  fanartIdx.value = Math.floor(Math.random() * props.items.length)
  fanartTimeout = setTimeout(nextFanart, FANART_DISPLAY_DURATION_MS)
}

onMounted(() => {
  fanartTimeout = setTimeout(nextFanart, FANART_DISPLAY_DURATION_MS)
})

onBeforeUnmount(() => {
  clearTimeout(fanartTimeout)
})
</script>
