<template>
  <div
    class="poll-display"
    :class="winnerChoice ? 'generating-episode' : 'poll-running'"
  >
    <IcBackground
      :scene="scene"
      :magic-dust="false"
      :modifiers="modifiers"
    />
    <IcFanart :items="info.fanartItems" />
    <IcPollChoices
      :choices="choices"
      :winner-choice="winnerChoice"
      :status-text="statusText"
    />
    <IcTwitchChat :messages="info.chatMessages" />
    <div
      class="poll-microphone"
      :class="`poll-microphone-${speakerCharacter.id}`"
    >
      &nbsp;
    </div>
    <IcCharacter
      ref="reader"
      :character="speakerCharacter"
      :sprite="speakerSprite"
      :class="speakstart ? 'speakstart' : undefined"
      @speakstart-ended="onSpeakstartEnded"
    />

    <div class="poll-display-speaker-text">
      <IcTypingText
        v-if="speakerText"
        :target-text="speakerText"
        :speed="30"
      />
      <div
        v-else
        class="text-scroll-wrapper"
      >
        <div class="text-container-text bouncy-text">
          <span class="bouncy-1">.</span>
          <span class="bouncy-2">.</span>
          <span class="bouncy-3">.</span>
        </div>
      </div>
    </div>

    <div class="poll-display-chat-hint">
      Talk to {{ speakerCharacter.name }} in chat!
    </div>

    <Transition>
      <div
        v-if="!winnerChoice"
        class="poll-display-vote-hint"
      >
        Vote for the next episode ->
      </div>
    </Transition>
  </div>
</template>
<script setup lang="ts">
import { computed, watch, ref, onMounted, onBeforeUnmount } from 'vue'
import { PollsRow, PollConversation, QueueInfoData, TwitchPollChoice, PollProcessingStatus } from '../../../common/src/Types'
import { CharacterDef, CHARACTERS, CharacterSprite, findSprite } from '../../../common/src/Characters'
import { getRandom } from '../../../common/src/Util'
import { TtsPlayer } from '../TtsPlayer'
import { determinePitch, determineTempo } from '../audio'
import { SCENES } from '../../../common/src/Scenes'
import IcBackground from './IcBackground.vue'
import IcFanart from './IcFanart.vue'
import IcPollChoices from './PollDisplay/IcPollChoices.vue'
import IcCharacter from './IcCharacter.vue'
import IcTwitchChat from './IcTwitchChat.vue'
import IcTypingText from './IcTypingText.vue'

const props = defineProps<{
  info: QueueInfoData,
  poll: PollsRow,
}>()

const WAIT_BEFORE_MESSAGES_MS = 750
const WAIT_BEFORE_COMMENTS_MS = 2500

const scene = getRandom(SCENES)

const messagesUntilComments = ref<number>(0)
const speaking = ref<boolean>(false)
const speakstart = ref<boolean>(false)
const speakerText = ref<string>('')
const speakerEmotion = ref<string>('')

const modifiers = computed(() => props.info?.modifiers.filter(m => m.value) || [])

const speakerCharacter = computed(() => {
  return CHARACTERS.find(ch => ch.id === props.poll.poll_character_id ) as CharacterDef
})
const speakerSprite = computed((): CharacterSprite => {
  return findSprite(speakerCharacter.value, speakerEmotion.value, scene) as CharacterSprite
})

const winnerChoice = computed(() => {
  if (props.poll.winning_choice) {
    return props.poll.poll_data.choices.find(ch => ch.letter === props.poll.winning_choice) || null
  }
  return null
})

const choices = computed(() => {
  const arr = []
  for (let i = 0; i < props.poll.poll_data.choices.length; i++) {
    arr.push({
      choice: props.poll.poll_data.choices[i],
      twitchChoice: props.poll.twitch_poll_data?.choices[i] as TwitchPollChoice,
    })
  }
  return arr
})

const statusText = computed((): string => {
  switch (props.info.pollProcessingStatus) {
    case PollProcessingStatus.ACTIVE: return 'Poll is active'
    case PollProcessingStatus.WAITING: return 'Finishing previous episode generation'
    case PollProcessingStatus.CREATING_SCRIPT: return 'Creating episode script'
    case PollProcessingStatus.PROCESSING_SCRIPT: return 'Processing script and generating voiceover'
    case PollProcessingStatus.FINISHED: return 'Finishing up'
    default: return ''
  }
})

const ttsPlayer = new TtsPlayer()

watch(modifiers, (val) => {
  ttsPlayer.setPitch(determinePitch(val, speakerCharacter.value, null))
  ttsPlayer.setTempo(determineTempo(val, speakerCharacter.value, null))
}, { deep: true })

const shouldStopSpeaking = ref<boolean>(true)
const abortController = new AbortController()
const speak = async () => {
  if (!shouldStopSpeaking.value) {
    console.log('not speaking anymore (stopped commenting)')
    return
  }

  const item = ttsPlayer.getNextFile()
  if (!item) {
    // try to load more voicefiles
    console.log('fetching next comment to speak it')
    await loadNextComment()
    return
  }

  const waitBetweenSpeak = messagesUntilComments.value > 0
    ? WAIT_BEFORE_MESSAGES_MS
    : WAIT_BEFORE_COMMENTS_MS

  // speak
  console.log(`now speaking~!!!! (in ${waitBetweenSpeak}ms)`)
  speaking.value = true

  if (waitBetweenSpeak > 0) {
    await new Promise<void>((resolve) => {
      setTimeout(resolve, waitBetweenSpeak)
    })
  }

  speakerText.value = item.text
  speakerEmotion.value = item.emotion
  try {
    await ttsPlayer.playFile(
      item.voicefile,
      determinePitch(modifiers.value, speakerCharacter.value, null),
      determineTempo(modifiers.value, speakerCharacter.value, null),
    )
  } catch (e) {
    console.log('unable to load audio buffer', e)
  }
  if (messagesUntilComments.value > 0) {
    messagesUntilComments.value--
  }
  speaking.value = false
  speakstart.value = false
  speakerText.value = ''
  speakerEmotion.value = ''
  await speak()
}

let commentTimeout: any = null
const loadNextComment = async () => {
  try {
    const res = await fetch(`/api/_do_twitch_conversation`, {
      signal: abortController.signal,
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        pollId: props.poll.id,
      }),
    })
    if (res.status === 401) {
      return
    }
    if (res.status === 200) {
      const json: { conversation: PollConversation } = await res.json()
      const newMessage = json.conversation.messages.pop()
      if (newMessage) {
        ttsPlayer.queueFiles({
          text: newMessage.content,
          emotion: newMessage.emotion || '',
          voicefile: newMessage.voicefile || '',
        })
        console.log('starting to speak (comment)')
      }
    }
  } catch (e) {
    console.log('error', e)
  }
  await speak()
}

const onSpeakstartEnded = () => {
  speakstart.value = false
}

onMounted(() => {
  if (!winnerChoice.value) {
    const toSpeak: string[] = []
    if (props.poll.poll_data.title.voicefile) {
      toSpeak.push(props.poll.poll_data.title.voicefile)
    }
    props.poll.poll_data.choices.forEach(choice => {
      if (choice.voicefile) {
        toSpeak.push(choice.voicefile)
      }
    })

    messagesUntilComments.value = toSpeak.length
    ttsPlayer.queueFiles(...toSpeak.map(f => ({
      text: '',
      emotion: '',
      voicefile: `/data/polls/${props.poll.id}/${f}`,
    })))

    console.log('PollDisplay mounted - starting to speak')
  }
  speak()
})

defineExpose({
  isSpeaking: (): boolean => {
    return speaking.value
  },
  stopSpeaking: () => {
    console.log('stop speaking')
    shouldStopSpeaking.value = false
  },
})

onBeforeUnmount(() => {
  console.log('PollDisplay onBeforeUnmount')
  abortController.abort()
  shouldStopSpeaking.value = false
  clearTimeout(commentTimeout)
  ttsPlayer.cleanup()
})
</script>
<style lang="scss">
.poll-microphone {
  background: url('/assets/interface/microphone_mark.png');
  background-size: cover;
  position: absolute;
  width: 200px;
  height: 200px;
  z-index: 2;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  &.poll-microphone-ayami { left: 60px; bottom: 0px; }
  &.poll-microphone-muri { left: 60px; bottom: 0px; }
  &.poll-microphone-mikiko { left: 60px; bottom: 0px; }
  &.poll-microphone-bloop { left: 10px; bottom: -30px; transform: rotate(35deg); }
  &.poll-microphone-john { left: 130px; bottom: 0px; }
  &.poll-microphone-kaya { left: 220px; bottom: 0px; }
  &.poll-microphone-kirino { left: 260px; bottom: 0px; }
  &.poll-microphone-lumi { left: 260px; bottom: 0px; }
  &.poll-microphone-steve { left: 260px; bottom: 0px; }
  &.poll-microphone-maestro { left: 230px; bottom: -20px; transform: rotate(-15deg); }
  &.poll-microphone-origami { left: 170px; bottom: 0px; }
  &.poll-microphone-kermito { left: 10px; bottom: -30px; transform: rotate(35deg); }
  &.poll-microphone-yato { left: 130px; bottom: -20px; }
  &.poll-microphone-yuvan { left: 60px; bottom: 0px; }
  &.poll-microphone-zerasu { left: 130px; bottom: -20px; }
}

.poll-display-fanart {
  position: absolute;

  width: 453px;
  height: 453px;
  left: 312px;
  top: 55px;

  background: rgba(0, 0, 0, 0.8);
  border: 5px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 30px;
  gap: 25px;

  .poll-display-fanart-image {
    width: 374px;
    height: 374px;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-size: cover;
    background-position: center center;
    transition: background-image 1s;

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .poll-display-fanart-image-next {
    right: -2000px;
    position: absolute;
    width: 374px;
    height: 374px;

    background-size: cover;
    background-position: center center;
  }
}

$dark_purple: #433450;
.poll-display-poll-items {
  position: absolute;
  width: 878px;
  height: 885px;
  left: 805px;
  top: 55px;

  background: rgba(0, 0, 0, 0.8);
  border: 5px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  background-clip: padding-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 63px 30px;
  gap: 48px;

  .poll-display-poll-item {
    width: 755px;
    height: 224px;
    background: $dark_purple;
    box-shadow: 0px 0px 4px rgba(81, 58, 65, 0.88);
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    .poll-display-choice-title {
      position: relative;
      background: #684957;
      height: 51px;
      box-shadow: 0px 0px 4px rgba(81, 58, 65, 0.88);
      border-radius: 10px;
      display: flex;
      padding: 8px 30px;
      justify-content: space-between;
      overflow: hidden;

      .poll-display-progress {
        background: #C586AE;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        transition: width 1s ease-in-out;
        z-index: 1;
      }

      .poll-display-poll-item-votes {
        position: relative;
        height: 35px;
        font-size: 24px;
        background: $dark_purple;
        line-height: 35px;
        border-radius: 35px;
        padding: 0 .5em;
        z-index: 2;
      }

      .poll-display-poll-item-letter {
        position: relative;
        width: 35px;
        height: 35px;
        background: $dark_purple;
        border-radius: 35px;
        text-align: center;
        font-family: 'Impact';
        font-size: 24px;
        line-height: 35px;
        color: rgba(255, 255, 255, 0.95);
        text-shadow: 0px 0px 2px rgba(81, 58, 65, 0.88);
        z-index: 2;
      }

      .poll-display-character-icon {
        width: 64px;
        height: 64px;
        position: relative;
        top: -16px;
        z-index: 2;
      }
    }

    .poll-display-poll-item-text {
      margin: 19px 30px;
      font-size: 32px;
      line-height: 1.25;
      color: rgba(255, 255, 255, 0.85);

      text-shadow: 0px 0px 2px rgba(81, 58, 65, 0.88);
      z-index: 2;
      height: 130px;
    }
  }
}

.poll-display-chat-hint {
  position: absolute;
  height: 65px;
  left: 817px;
  top: 22px;

  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 25px;

  font-weight: 400;
  font-size: 40px;
  line-height: 65px;
  color: #000000;
  padding: 0 .5em;
}

.poll-display-vote-hint {
  position: absolute;
  height: 65px;
  right: 145px;
  top: 22px;

  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 25px;

  font-weight: 400;
  font-size: 40px;
  line-height: 65px;
  color: #000000;
  padding: 0 1em;
}

.poll-display-generating-episode {
  font-size: 48px;
  line-height: 48px;
  .v-icon {
    font-size: 48px;
    line-height: 48px;
    margin-top: -8px;
  }
}

.poll-display .character {
  bottom: -350px;
  .modifier-size {
    transform: scale(1.2);
  }
  &.speakstart {
    animation: speak_wobble .8s forwards linear;
  }
}

.bouncy-text {
  span {
    display: inline-block;
    font-size: 2em;
  }
  .bouncy-1 {
    animation: bounce 2s ease infinite;
  }
  .bouncy-2 {
    animation: bounce 2s ease infinite .1s;
  }
  .bouncy-3 {
    animation: bounce 2s ease infinite .2s;
  }
}

@keyframes bounce {
  0%   { transform: translateY(0); }
  10%  { transform: translateY(0); }
  30%  { transform: translateY(-15%);}
  50%  { transform: translateY(1%); }
  58%  { transform: translateY(-3%); }
  65%  { transform: translateY(0);}
  100% { transform: translateY(0);}
}

@keyframes speak_wobble {
  0%, 20%, 50%, 80%, 100% {transform: translateY(4px);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}
</style>
