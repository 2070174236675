<template>
  <div class="characters-container">
    <div
      class="character-sprite-wrapper-1"
      :class="character1Classes"
    >
      <div
        v-if="showCharacters && character1Style"
        class="character-sprite-wrapper-size"
        :style="character1Style"
      >
        <!-- <div class="headgear" /> -->
      </div>
    </div>
    <div
      class="character-sprite-wrapper-1"
      :class="character2Classes"
    >
      <div
        v-if="showCharacters && character2Style"
        class="character-sprite-wrapper-size"
        :style="character2Style"
      >
        <!-- <div class="headgear" /> -->
      </div>
    </div>
    <div
      v-if="character1NextStyle"
      :style="character1NextStyle"
      class="character-next"
    />
    <div
      v-if="character2NextStyle"
      :style="character2NextStyle"
      class="character-next"
    />
  </div>
  <div class="ingame-bottom">
    <EpisodesInfoContainer
      v-if="line"
      :info="info"
    />
    <div
      v-if="targetText && transitioning === -1"
      class="visual-container text-container"
    >
      <IcTypingText
        v-if="targetText"
        ref="textComp"
        :target-text="targetText"
        :speed="typingSpeed"
        @finished-typing="onFinishedTyping"
      />
      <div class="end-icon">
        <img
          v-if="!isTyping"
          src="/assets/interface/end-icon-back.svg"
          class="end-icon-back"
        >
        <img
          v-if="!isTyping"
          src="/assets/interface/end-icon-front.svg"
          class="end-icon-front"
        >
      </div>
    </div>
    <div
      v-else
      class="text-container"
    />
    <PromptInfoContainer
      v-if="line"
      :info="info"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { CHARACTERS, CharacterSprite } from '../../../../common/src/Characters'
import { VnPlayerLine } from '../../Types'
import PromptInfoContainer from './PromptInfoContainer.vue'
import EpisodesInfoContainer from './EpisodesInfoContainer.vue'
import { ModifierName, QueueInfoData } from '../../../../common/src/Types'
import IcTypingText from '../IcTypingText.vue'

const props = defineProps<{
  vnPlayerLines: VnPlayerLine[]
  scriptPointer: number
  targetText: string
  isTyping: boolean
  typingSpeed: number
  info: QueueInfoData | null
  transitioning: number
  showCharacters: boolean
}>()

const emit = defineEmits<{
  (e: 'finished-typing'): void
}>()

const onFinishedTyping = () => {
  emit('finished-typing')
}

const getLine = (idx: number) => {
  if (idx >= 0 && idx < props.vnPlayerLines.length) {
    return props.vnPlayerLines[idx]
  }
  return null
}
const line = computed((): VnPlayerLine | null => getLine(props.scriptPointer))
const prevLine = computed((): VnPlayerLine | null => getLine(props.scriptPointer - 1))
const nextLine = computed((): VnPlayerLine | null => getLine(props.scriptPointer + 1))
const character1 = computed(() => CHARACTERS.find(ch => ch.id === line.value?.character1Id))
const character2 = computed(() => CHARACTERS.find(ch => ch.id === line.value?.character2Id))

const textComp = ref<InstanceType<typeof IcTypingText>>()
const character1Classes = computed(() => {
  const classes = ['character character-1']
  if (!character1Changed.value && isCharacter1Active.value) {
    classes.push('character-active')
  }
  if (character1Changed.value) {
    classes.push('character-changed')
  }
  if (character1.value) {
    classes.push('character-' + character1.value.id)
  }
  return classes
})

const character2Classes = computed(() => {
  const classes = ['character character-2']
  if (!character2Changed.value && isCharacter2Active.value) {
    classes.push('character-active')
  }
  if (character2Changed.value) {
    classes.push('character-changed')
  }
  if (character2.value) {
    classes.push('character-' + character2.value.id)
  }
  return classes
})


const character1Changed = ref<boolean>(false)
const character2Changed = ref<boolean>(false)
watch(() => props.scriptPointer, () => {
  if (isCharacter1Active.value && (!prevLine.value || prevLine.value.character1Id !== line.value?.character1Id)) {
    character1Changed.value = true
    setTimeout(() => {
      character1Changed.value = false
    }, 20)
  }
  if (isCharacter2Active.value && (!prevLine.value || prevLine.value.character2Id !== line.value?.character2Id)) {
    character2Changed.value = true
    setTimeout(() => {
      character2Changed.value = false
    }, 20)
  }

  textComp.value?.startTyping()
})

const isCharacter1Active = computed(() => line.value?.characterActive === 1)
const isCharacter2Active = computed(() => line.value?.characterActive === 2)
const character1Style = computed(() => characterStyle(line.value?.character1Sprite || null))
const character2Style = computed(() => characterStyle(line.value?.character2Sprite || null))
const character1NextStyle = computed(() => characterStyle(nextLine.value?.character1Sprite || null))
const character2NextStyle = computed(() => characterStyle(nextLine.value?.character2Sprite || null))

const characterStyle = (sprite: CharacterSprite | null) => {
  if (!sprite) {
    return undefined
  }
  const spriteImage =
    props.info?.modifiers.some(m => m.name === ModifierName.STAR_TREK)
    ? sprite.image.replace(/(origami|ayami)\/(casual|dress|school uniform)\//g, '$1/startrek/')
    : sprite.image
  return {
    backgroundImage: `url(/assets/sprites/${spriteImage.split('/').map(encodeURIComponent).join('/')})`,
    width: `${sprite.width}px`,
    height: `${sprite.height}px`,
  }
}
</script>
