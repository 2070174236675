import SimpleFilter from './SimpleFilter'

export class WebAudioNode {
  private samples: Float32Array
  private node: ScriptProcessorNode
  private filter: SimpleFilter
  private bufferSize: number

  private onEventBound: (evt: AudioProcessingEvent) => void

  constructor(
    context: AudioContext,
    filter: SimpleFilter,
    bufferSize: number,
  ) {
    this.bufferSize = bufferSize
    this.filter = filter
    this.samples = new Float32Array(bufferSize * 2)
    this.node = context.createScriptProcessor(bufferSize, 2, 2)
    this.onEventBound = this.onEvent.bind(this)
    this.node.addEventListener('audioprocess', this.onEventBound)
  }

  onEvent(event: AudioProcessingEvent) {
    const left = event.outputBuffer.getChannelData(0)
    const right = event.outputBuffer.getChannelData(1)
    const framesExtracted = this.filter.extract(this.samples, this.bufferSize)
    if (framesExtracted === 0) {
      this.filter.onEnd()
    }
    for (let i = 0; i < framesExtracted; i++) {
      left[i] = this.samples[i * 2]
      right[i] = this.samples[i * 2 + 1]
    }
  }

  connect(toNode: AudioNode) {
    this.node.connect(toNode)
  }

  disconnect() {
    this.node.removeEventListener('audioprocess', this.onEventBound)
    this.node.disconnect()
  }
}
