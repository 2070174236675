<template>
  <div>
    <v-banner class="d-flex flew-row justify-end px-12">
      <v-btn :disabled="!output" variant="tonal" size="small" color="secondary" class="mr-4" @click="queueTestScript">
        Queue episode to play live
      </v-btn>
      <v-btn color="primary" :disabled="!testPrompt" @click="sendPrompt">
        Generate output
      </v-btn>
    </v-banner>
    <div class="d-flex">
      <div class="prompt-tester-input-section">
        <div class="prompt-input-area">
          <v-textarea v-model="testPrompt" label="Prompt" rows="16" variant="solo" />
        </div>
        <v-btn @click="addRandomCharacterShort">
          Add random character (short)
        </v-btn>
        <v-btn @click="addRandomCharacterLong">
          Add random character (long)
        </v-btn>
        <div class="d-flex flex-row justify-start">
          <v-select v-model="chosenCharacter" hide-details class="mx-6" density="compact" :items="characters"
            variant="solo" />
          <v-btn @click="addChosenCharacter">
            Add
          </v-btn>
        </div>
        <v-btn @click="testPrompt = getEpisodePrompt">
          Replace with current episode prompt
        </v-btn>
        <v-btn @click="testPrompt = getSequelPrompt">
          Replace with current sequel prompt
        </v-btn>
        <div>
          <v-slider v-model="testTemperature" step="0.1" show-ticks :max="2" :min="0" class="align-center" hide-details>
            <template #append>
              <v-text-field v-model="testTemperature" density="compact" style="width: 100px" type="number" hide-details
                single-line />
            </template>
          </v-slider>
        </div>
        <div>
          <v-select v-model="testApiType" hide-details class="mx-6" density="compact" :items="apiTypes"
            variant="solo" />
        </div>
      </div>
      <div style="flex: 1">
        <div v-if="error">
          {{ error }}
        </div>
        <div v-else-if="generating">
          Generating output...
        </div>
        <div v-else>
          <v-textarea v-model="output" rows="32" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { CHARACTERS, charRandomShortDescriptions, charRandomLongDescriptions, findCharacter } from '../../../../common/src/Characters'
import { getEpisodeCreationPrompt, createCharacterDescriptions, PromptCommand } from '../../../../common/src/Prompt'
import { ApiType } from '../../../../common/src/Types'
import { toast } from '../../toast'

const characters = ref<string[]>(CHARACTERS.map(ch => ch.name))
const generating = ref<boolean>(false)
const error = ref<string>('')
const output = ref<string>('')
const testTemperature = ref<number>(1)
const testApiType = ref<ApiType>(ApiType.ANTHROPIC_AI)
const apiTypes = Object.values(ApiType)
const chosenCharacter = ref<string>(CHARACTERS[0].name)

const addChosenCharacter = () => {
  const char = findCharacter(chosenCharacter.value)
  if (char) {
    testPrompt.value += `\n\n`
    testPrompt.value += createCharacterDescriptions([char])
  }
}

const getEpisodePrompt = computed((): string => {
  return getEpisodeCreationPrompt('In this episode, a character helps out in a cafe.', [CHARACTERS[0], CHARACTERS[1]], [] as PromptCommand[], null)
})

const getSequelPrompt = computed((): string => {
  return getEpisodeCreationPrompt('', [CHARACTERS[0], CHARACTERS[1]], [] as PromptCommand[], 'PREQUEL EPISODE')
})

const testPrompt = ref<string>(getEpisodePrompt.value)

const addRandomCharacterShort = () => {
  testPrompt.value += `\n\n`
  testPrompt.value += charRandomShortDescriptions(1)
}

const addRandomCharacterLong = () => {
  testPrompt.value += `\n\n`
  testPrompt.value += charRandomLongDescriptions(1)
}

const sendPrompt = async () => {
  generating.value = true
  error.value = ''
  output.value = ''
  const res = await fetch(`/api/_test_custom_prompt`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      testPrompt: testPrompt.value,
      testTemperature: testTemperature.value,
      testApiType: testApiType.value,
    }),
  })
  if (res.status === 200) {
    const json = await res.json()
    output.value = json.content
  } else {
    error.value = 'An error occured while talking to the AI...'
  }
  generating.value = false
}

const queueTestScript = async () => {
  if (!output.value) return
  generating.value = true
  const res = await fetch(`/api/_queue_test_script`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      testScript: output.value,
    }),
  })
  if (res.status === 200) {
    toast('Script queued!', 'success')
  } else {
    toast('An error occured while queueing...', 'error')
  }
  generating.value = false
}
</script>

<style lang="scss" scoped>
.prompt-tester-input-section {
  flex: 1;
  display: flex;
  flex-direction: column;

  .v-btn {
    margin: 6px 24px;
  }

  .v-select {
    margin: 6px 24px;
  }
}
</style>
