import { debug } from './util'

enum Codes {
  CLOSE_NORMAL = 1000, // Successful operation / regular socket shutdown
  CLOSE_GOING_AWAY = 1001, // Client is leaving (browser tab closing)
  CLOSE_PROTOCOL_ERROR = 1002, // Endpoint received a malformed frame
  CLOSE_UNSUPPORTED = 1003, // Endpoint received an unsupported frame (e.g. binary-only endpoint received text frame)
  // 1004	Reserved
  CLOSED_NO_STATUS = 1005, // Expected close status, received none
  CLOSE_ABNORMAL = 1006, // No close code frame has been receieved
  UNSUPPORTED_PAYLOAD = 1007, // Endpoint received inconsistent message (e.g. malformed UTF-8)
  POLICY_VIOLATION = 1008, // Generic code used for situations other than 1003 and 1009
  CLOSE_TOO_LARGE = 1009, // Endpoint won't process large frame
  MANDATORY_EXTENSION = 1010, // Client wanted an extension which server did not negotiate
  SERVER_ERROR = 1011, // Internal server error while operating
  SERVICE_RESTART = 1012, // Server/service is restarting
  TRY_AGAIN_LATER = 1013, // Temporary server condition forced blocking client's request
  BAD_GATEWAY = 1014, // Server acting as gateway received an invalid response
  TLS_HANDSHAKE_FAIL = 1015,

  // application codes (4000-....)
  CUSTOM_DISCONNECT = 4000
}

type CallbackFn = (...args: any[]) => void

/**
 * Wrapper around ws that
 * - buffers 'send' until a connection is available
 * - automatically tries to reconnect on close
 */
export default class WsClient {
  private id: string | null = null

  // actual ws handle
  private handle: WebSocket | null = null

  // timeout for automatic reconnect
  private reconnectTimeout: NodeJS.Timeout | null = null

  // buffer for 'send'
  private sendBuffer: string[] = []

  private timerId: any = 0
  private gotPong: boolean = false
  private pongWaitTimerId: any = 0

  private _on: Record<string, Record<string, CallbackFn[]>> = {}

  constructor(
    private readonly addr: string,
  ) {
  }

  public send(txt: string) {
    if (this.handle) {
      this.handle.send(txt)
    } else {
      this.sendBuffer.push(txt)
    }
  }

  public onMessage(tag: string | string[], callback: CallbackFn) {
    this.addEventListener('message', tag, callback)
  }

  public async connect(): Promise<void> {
    return new Promise((resolve) => {
      const id = this.generateId()
      this.id = id
      const reconnect = () => {
        if (this.reconnectTimeout) {
          clearTimeout(this.reconnectTimeout)
        }
        this.reconnectTimeout = setTimeout(() => { this.connect() }, 5000)
      }

      debug({ id, addr: this.addr }, 'trying to connect')
      const ws = new WebSocket(this.addr)
      ws.onopen = (e) => {
        debug({ id }, 'ws connected')
        if (id !== this.id) {
          // this is not the last connection.. ignore it
          debug({
            id,
            this_id: this.id,
          }, 'connected but it is not the last attempt')
          ws.close(Codes.CUSTOM_DISCONNECT)
          return
        }

        if (this.handle) {
          // should not happen...
          console.error({ e }, 'handle already existed, closing old one and replacing it')
          this.handle.close(Codes.CUSTOM_DISCONNECT)
        }
        this.handle = ws
        // should have a queue worker
        while (this.sendBuffer.length > 0) {
          const text = this.sendBuffer.shift()
          if (text) {
            this.handle.send(text)
          }
        }
        this._dispatch('socket', 'open', e)
      }
      debug('setting up onmessage')
      ws.onmessage = (e) => {
        debug('onmessage', e)

        if (e.data === 'SERVER_INIT') {
          this.keepAlive()
          resolve()
          return
        }

        if (e.data === 'PONG') {
          this.gotPong = true
          return
        }

        this._dispatch('socket', 'message', e)
        if (this._on['message']) {
          const d = this._parseMessageData(e.data)
          if (d.event) {
            this._dispatch('message', `${d.event}`, d.data, d)
          }
        }
      }
      ws.onerror = (e) => {
        this.cancelKeepAlive()
        console.error({ e, id })
        // this will cause a close with reason 1006
        // reconnect will automatically happen
      }
      ws.onclose = (e) => {
        debug('onclose', e)
        this.cancelKeepAlive()
        this.handle = null
        this._dispatch('socket', 'close', e)
        if (e.code === Codes.CUSTOM_DISCONNECT) {
          debug({ id }, 'custom disconnect, will not reconnect')
        } else if (e.code === Codes.CLOSE_GOING_AWAY) {
          debug({ id }, 'going away, will not reconnect')
        } else {
          debug({ id, code: e.code }, 'connection closed, trying to reconnect.')
          reconnect()
        }
      }
    })
  }

  public disconnect() {
    if (this.handle) {
      debug({ code: Codes.CUSTOM_DISCONNECT }, 'handle existed, closing')
      this.handle.close(Codes.CUSTOM_DISCONNECT)
    }
  }

  private keepAlive(timeout = 20000) {
    if (this.handle && this.handle.readyState == this.handle.OPEN) {
      this.gotPong = false
      debug(this.id, 'sending ping')
      this.handle.send('PING')
      if (this.pongWaitTimerId) {
        clearTimeout(this.pongWaitTimerId)
      }
      this.pongWaitTimerId = setTimeout(() => {
        if (!this.gotPong && this.handle) {
          // close without custom disconnect, to trigger reconnect
          debug(this.id, 'triggering close because no pong')
          this.handle.close()
        }
      }, 1000) // server should answer more quickly in reality
    }
    this.timerId = setTimeout(() => {
      this.keepAlive(timeout)
    }, timeout)
  }

  private cancelKeepAlive() {
    if (this.timerId) {
      clearTimeout(this.timerId)
    }
  }

  private generateId () {
    const length = 10
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }

  private addEventListener(type: string, tag: string | string[], callback: CallbackFn) {
    const tags = Array.isArray(tag) ? tag : [tag]
    this._on[type] = this._on[type] || {}
    for (const t of tags) {
      this._on[type][t] = this._on[type][t] || []
      this._on[type][t].push(callback)
    }
  }

  private _parseMessageData(data: string) {
    try {
      const d = JSON.parse(data)
      if (d.event) {
        d.data = d.data || null
        return d
      }
    } catch (e) {
      debug({ e })
    }
    return { event: null, data: null }
  }

  private _dispatch(type: string, tag: string, ...args: any[]) {
    const t = this._on[type] || {}
    const callbacks = (t[tag] || [])
    if (callbacks.length === 0) {
      return
    }

    debug({ type, tag }, 'ws dispatch')
    for (const callback of callbacks) {
      callback(...args)
    }
  }
}
