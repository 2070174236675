<template>
  <LogDialog
    v-model="openLogs"
    :entity-type="'episode'"
    :entity-id="episode.id"
    :title="`${episode.title?.text || ''} ${episode.prompt_topic ? `(${episode.prompt_topic})` : ''}`"
  />
  <v-dialog
    v-model="openDialog"
    max-width="700px"
    min-height="600px"
    scrollable
  >
    <v-card class="px-4">
      <v-btn
        v-if="!isGuest"
        class="mt-4"
        variant="tonal"
        @click="openLogs = true"
      >
        <v-icon>
          mdi-robot-happy
        </v-icon>
        Show episode creationg logs
      </v-btn>
      <div class="mt-4 mx-8">
        {{ episode.title?.text }} {{ episode.prompt_topic ? `(${episode.prompt_topic})` : '' }}
      </div>
      <v-divider class="mt-2" />
      <v-table>
        <tbody>
          <tr
            v-for="(line, idx2) in episode.script || []"
            :key="idx2"
          >
            <td
              v-if="line.type === 'dialog'"
              style="width:70px;"
              class="pa-1"
            >
              <div
                class="character-sprite"
                :style="characterSpriteStyle(line)"
              />
            </td>
            <td
              v-if="line.type === 'dialog'"
              class="pa-1"
            >
              <div>{{ line.data.actor }}</div>
              <div class="text-caption text-grey mb-3">
                {{ line.data.emotion }}
              </div>
              <div>
                {{ line.data.text }}
              </div>
            </td>
            <td
              v-if="line.type === 'narration'"
              colspan="2"
              class="pa-1"
            >
              <div><em>{{ line.data.text }}</em></div>
            </td>
            <td
              v-if="line.type === 'location'"
              colspan="2"
              class="pa-1"
            >
              <div>LOCATION: <code>{{ line.data.location }} ({{ line.data.sceneId }})</code></div>
              <div>
                <span>📌 {{ scene(line.data.sceneId).name }}</span>
                <span v-if="idx2 === 0">
                  👘 {{ scene(line.data.sceneId).clothingStyle }}
                </span>
              </div>
              <img
                :src="`/assets/scenes-5/${scene(line.data.sceneId).imageNiji5}`"
                width="320"
              >
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </v-dialog>
  <v-card 
    :image="`/assets/scenes-5/${beginningScene.imageNiji5}`"
    class="control-episode-item"
    elevation="2"
    :loading="episode.status === EpisodeStatus.PROCESSING"
  >
    <div class="d-flex flex-column mx-4 mt-2">
      <div class="speech-bubbles-container">
        <div
          v-if="episode.prompt_username"
          class="speech-bubble left"
        >
          <div
            v-if="props.episode.prompt_type === PromptType.TWITCH_REDEMPTION || props.episode.prompt_type === PromptType.TWITCH_COMMAND"
            class="ml-2 font-weight-bold text-subtitle-1 text-medium-emphasis"
          >
            <span
              :class="twitchColor"
            >@{{ episode.prompt_username }}</span> redeemed ✨Episode Topic✨
          </div>
          <div
            v-if="props.episode.prompt_type === PromptType.TWITCH_SEQUEL_COMMAND || props.episode.prompt_type === PromptType.TWITCH_SEQUEL_REDEMPTION"
            class="ml-2 font-weight-bold text-subtitle-1 text-medium-emphasis"
          >
            <span
              :class="twitchColor"
            >@{{ episode.prompt_username }}</span> redeemed ⏩Sequel Episode⏩
          </div>
          <div
            v-else-if="props.episode.prompt_type === PromptType.TWITCH_POLL"
            class="ml-2 font-weight-bold text-subtitle-1 text-medium-emphasis"
          >
            Twitch poll:
          </div>
          <v-divider />
          <div
            v-if="episode.prompt_topic"
            class="ml-2 mt-2 text-body-2 text-medium-emphasis"
          >
            {{ episode.prompt_topic }}
          </div>
          <v-btn
            v-if="props.episode.prompt_topic"
            class="hover-copy-button"
            size="x-small"
            icon="mdi-clipboard-file-outline"
            @click="copyPromptToClipboard()"
          />
        </div>
        <div
          v-if="props.episode.status === 'aborted'"
          class="speech-bubble right"
        >
          <div class="font-weight-bold text-overline text-warning">
            ✋ The episode was cancelled by the admins.
          </div>
        </div>
        <div
          v-else-if="props.episode.status === 'error'"
          class="speech-bubble right"
        >
          <div class="font-weight-bold text-overline text-error">
            🤖 An error occured, no episode was generated.
          </div>
        </div>
        <div
          v-else-if="props.episode.status === 'flagged'"
          class="speech-bubble right"
        >
          <div class="font-weight-bold text-overline text-error">
            😳 No appropriate script could be generated.
          </div>
        </div>
      </div>
      <span class="episode-title d-flex flex-wrap align-center">
        <img
          v-for="(ch, idx) in characters"
          :key="idx"
          :src="`/assets/character-icons/${ch.id}.png`"
          class="episode-character-icon"
        > <span class="episode-title-text"> {{ episode.title?.text }}</span>
      </span>
    </div>
    <v-card-text class="flat-color">
      <div class="episode-info">
        <v-chip
          v-if="canViewEpisodeScript"
          size="small"
          class="mr-2"
          prepend-icon="mdi-nature-people"
          color="primary"
          @click="openDialog = true"
        >
          <span>Show script</span>
        </v-chip>
        <v-chip
          size="small"
          class="mr-2"
          prepend-icon="mdi-clipboard-file-outline"
          @click="copyIdToClipboard()"
        >
          <span>{{ `ID: ${episode.id}` }}</span>
        </v-chip>
        <v-chip
          v-if="!canCopyEpisodeScript"
          size="x-small"
          class="mr-2"
        >
          <span>{{ episode.script?.length || 0 }} lines of script</span>
        </v-chip>
        <v-chip
          v-if="canCopyEpisodeScript"
          size="x-small"
          class="mr-2"
          prepend-icon="mdi-clipboard-file-outline"
          @click="copyScriptToClipboard()"
        >
          <span>{{ episode.script?.length || 0 }} lines of script</span>
        </v-chip>
        <v-chip
          v-if="episode.last_played"
          size="x-small"
          class="mr-2"
          variant="text"
        >
          <span>{{ `played at ${lastPlayedDate}` }}</span>
        </v-chip>
        <v-chip
          v-else
          size="x-small"
          class="mr-2"
          disabled
          variant="text"
        >
          not played yet...
        </v-chip>
      </div>
      <v-divider
        v-if="!isGuest"
        class="my-2"
      />
      <div
        v-if="!isGuest && episode.status === EpisodeStatus.PROCESSED"
        class=" d-flex flex-row justify-center align-center"
      >
        <v-btn
          variant="outlined"
          color="blue-grey-lighten-2"
          density="compact"
          class="ml-3"
          @click="addToPlaylist"
        >
          Add to Playlist
        </v-btn>
        <v-btn
          variant="outlined"
          color="blue-grey-lighten-2"
          density="compact"
          class="ml-3"
          @click="setPlayed"
        >
          +1 Play Count
        </v-btn>
      </div>
      <v-btn
        v-if="!isGuest && episode.status === EpisodeStatus.PROCESSING"
        color="red"
        block
        @click="cancelJob"
      >
        Cancel job
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script
  setup
  lang="ts"
>
import { computed, ref } from 'vue'
import { getSceneById } from '../../../../common/src/Scenes'
import { DialogScriptLine, SceneDef } from '../../../../common/src/Types'
import { CharacterDef, CHARACTERS, episodeCharacters, findCharacter, findSprite } from '../../../../common/src/Characters'
import { EpisodesRow, EpisodeStatus } from '../../../../common/src/EpisodesRow'
import { PromptType } from '../../../../common/src/PromptDef'
import LogDialog from './LogDialog.vue'
import user from '../../user'
import { format } from 'date-fns'
import { toast } from '../../toast'
import { twitchColorToId } from '../../../../common/src/TwitchColors'
import { handleCopyingToClipboard } from '../../util'

const isGuest = computed(() => !user.getMe())

const openLogs = ref<boolean>(false)
const openDialog = ref<boolean>(false)

const props = defineProps<{
  episode: EpisodesRow,
}>()

const emit = defineEmits<{
  (e: 'add-to-playlist', val: EpisodesRow): void
  (e: 'set-played', val: EpisodesRow): void
  (e: 'cancel-job', val: EpisodesRow): void
}>()

const addToPlaylist = async () => {
  emit('add-to-playlist', props.episode)
}

const setPlayed = async () => {
  emit('set-played', props.episode)
}

const cancelJob = async () => {
  emit('cancel-job', props.episode)
}

const copyIdToClipboard = async () => {
  handleCopyingToClipboard(` --id ${props.episode.id}`)
  toast('Copied to clipboard: --id ' + props.episode.id, 'success')
}

const copyScriptToClipboard = async () => {
  if (!props.episode.gpt_output) {
    return
  }
  handleCopyingToClipboard(props.episode.gpt_output.toString())
  toast('Copied script of episode ' + props.episode.id, 'success')
}

const copyPromptToClipboard = async () => {
  if (!props.episode.prompt_topic) {
    return
  }
  handleCopyingToClipboard(props.episode.prompt_topic)
  toast('Copied prompt to clipboard', 'success')
}

const lastPlayedDate = computed(() => {
  if (!props.episode.last_played) {
    return ''
  }
  return format(new Date(props.episode.last_played), 'HH:mm dd MMM yyyy')
})


const canViewEpisodeScript = computed(() => {
  if (!isGuest.value) {
    return true
  }
  if (props.episode.last_played) {
    return true
  }
  return false
})

const canCopyEpisodeScript = computed(() => {
  return canViewEpisodeScript.value  && props.episode.gpt_output
})

const characterSpriteStyle = computed(() => (line: DialogScriptLine) => {
  const templateIcon = {
    backgroundImage: ``,
  }
  const ch = findCharacter(line.data.actor || '')
  if (!ch) {
    return templateIcon
  }
  if (isGuest.value) {
    return {
      backgroundImage: `url(/assets/character-icons/${ch.id}.png)`,
    }

  }
  const episodeCharacterIds = props.episode.character_ids || CHARACTERS.map(ch => ch.id)
  if (!episodeCharacterIds.includes(ch.id)) {
  return templateIcon
  }
  const sprite = findSprite(ch, line.data.emotion, beginningScene.value)
  if (sprite) {
    return {
      backgroundImage: `url(/assets/sprites/${sprite.image.split('/').map(encodeURIComponent).join('/')})`,
    }
  }
  return templateIcon
})

const twitchColor = computed(() => {
  return `twitch-color-${twitchColorToId(props.episode.prompt_usercolor)}`
})

const scene = (sceneId: any): SceneDef => getSceneById(sceneId)

const beginningScene = computed((): SceneDef => getSceneById(props.episode.scene_id))

const characters = computed((): CharacterDef[] => episodeCharacters(props.episode))

</script>
