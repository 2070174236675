<template>
  <div class="container">
    <v-tabs
      v-model="tab"
      bg-color="primary"
      class="control-view-tabs"
    >
      <v-tab value="custom-prompt-tester">
        Prompt Tester
      </v-tab>
      <v-tab value="polls">
        Polls
      </v-tab>
      <v-tab value="poll-creator">
        Poll Creator
      </v-tab>
      <v-tab value="fanart">
        Fanart
      </v-tab>
      <v-tab value="voicetest">
        Voicetest
      </v-tab>
      <v-tab value="settings">
        Settings
      </v-tab>
      <v-btn
        href="/episodes"
        color="background"
        variant="text"
        class="ml-auto my-1 mr-8"
      >
        Go to episodes
      </v-btn>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item value="custom-prompt-tester">
        <CustomPromptTester />
      </v-window-item>
      <v-window-item value="polls">
        <PollsListing />
      </v-window-item>
      <v-window-item value="poll-creator">
        <PollCreator />
      </v-window-item>
      <v-window-item value="fanart">
        <FanartSettings />
      </v-window-item>
      <v-window-item value="voicetest">
        <VoiceTest />
      </v-window-item>
      <v-window-item value="settings">
        <IcSettings />
      </v-window-item>
    </v-window>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { ref } from 'vue'
import CustomPromptTester from '../components/Control/CustomPromptTester.vue'
import PollsListing from '../components/Control/PollsListing.vue'
import PollCreator from '../components/Control/PollCreator.vue'
import FanartSettings from '../components/Control/FanartSettings.vue'
import VoiceTest from '../components/Control/VoiceTest.vue'
import IcSettings from '../components/Control/IcSettings.vue'

const tab = ref<'custom-prompt-tester' | 'polls' | 'poll-creator' | 'fanart' | 'voicetest' | 'settings'>('custom-prompt-tester')
</script>
