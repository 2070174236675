import { ScriptLine } from './Types'
import { SceneId } from './Scenes'
import { PromptType } from './PromptDef'
import { CharacterId } from './Characters'

export enum EpisodeStatus {
  PROCESSED = 'processed',
  ERROR = 'error',
  PROCESSING = 'processing',
  FLAGGED = 'flagged',
  ABORTED = 'aborted',
}

export interface EpisodeTitle {
  text: string
  voicefile?: string
}

export interface EpisodesRow {
  id: number
  episode_id: string
  played: number
  created: string // json date string
  creation_log: null // deprecated
  status: EpisodeStatus
  prompt_topic: string | null
  prompt_username: string | null
  prompt_usercolor: string | null
  prompt_type: PromptType | null
  prompt_poll_id: number | null
  prompt_prequel_episode_id: number | null
  scene_id: SceneId | null
  title: EpisodeTitle | null
  script: ScriptLine[] | null
  character_ids: CharacterId[] | null
  last_played: string // json date string
  gpt_output: string | null
}
