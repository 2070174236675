<template>
  <div
    v-if="episode.title?.text"
    class="title-container"
  >
    <div class="title-container-text">
      {{ episode.title.text }}
    </div>
    <div
      v-if="episode.prompt_type === PromptType.TWITCH_SEQUEL_COMMAND || episode.prompt_type === PromptType.TWITCH_SEQUEL_REDEMPTION"
      class="title-prompt-creator"
    >
      Sequel to episode #{{ episode.prompt_prequel_episode_id }}
    </div>
    <div
      v-else-if="username"
      class="title-prompt-creator"
    >
      Prompt by <span :class="twitchColor">@{{ username }}</span>
    </div>
    <div
      class="decoration-1"
      :class="decorationClass"
    >
      <TwitchSvg
        v-if="username"
        :filled="true"
      />
    </div>
    <div
      class="decoration-2"
      :class="decorationClass"
    >
      <TwitchSvg
        v-if="username"
        :filled="true"
      />
    </div>
    <div
      class="decoration-3"
      :class="decorationClass"
    >
      <TwitchSvg
        v-if="username"
        :filled="true"
      />
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { computed } from 'vue'
import { EpisodesRow } from '../../../../common/src/EpisodesRow'
import { twitchColorToId } from '../../../../common/src/TwitchColors'
import TwitchSvg from './TwitchSvg.vue'
import { PromptType } from '../../../../common/src/PromptDef'

const props = defineProps<{
  episode: EpisodesRow
}>()

const username = computed(() => {
  return props.episode.prompt_username
})

const decorationClass = computed(() => {
  if (username.value) {
    return ['decoration-twitch', twitchColor.value]
  }
  return 'decoration-default'
})

const twitchColor = computed(() => {
  return `twitch-color-${twitchColorToId(props.episode.prompt_usercolor)}`
})
</script>
