<template>
  <div
    ref="textScrollWrapper"
    class="text-scroll-wrapper"
  >
    <div
      ref="textEl"
      class="text-container-text"
      :class="textClass"
      :style="textStyle"
    >
      {{ text }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, Ref, onMounted, onBeforeUnmount, watch, nextTick } from 'vue'

const props = defineProps<{
  targetText: string
  speed: number
}>()

const emit = defineEmits<{
  (e: 'finished-typing'): void
}>()

const textEl = ref<HTMLDivElement>() as Ref<HTMLDivElement>
const textScrollWrapper = ref<HTMLDivElement>() as Ref<HTMLDivElement>

const isTyping = ref<boolean>(false)
const text = ref<string>('')
const textStyle = ref<any>(undefined)

const textClass = computed(() => {
  if (props.targetText.length > 250) {
    return 'text-smallest'
  }
  if (props.targetText.length > 200) {
    return 'text-smaller'
  }
  return ''
})

let timeout: any = null
const startTyping = (): void => {
  if (isTyping.value) {
    return
  }

  isTyping.value = true
  text.value = ''

  let letterIdx = 0
  const nextLetter = () => {
    if (text.value === props.targetText) {
      emit('finished-typing')
      stopTyping()
    } else {
      text.value += props.targetText[letterIdx]
      letterIdx++
      timeout = setTimeout(nextLetter, props.speed)
    }
  }
  nextLetter()
}

const stopTyping = () => {
  if (timeout) {
    clearTimeout(timeout)
  }
  isTyping.value = false
}

watch(() => props.targetText, () => {
  stopTyping()
  startTyping()
})

watch(text, () => {
  nextTick(() => {
    if (!textEl.value || !textScrollWrapper.value) {
      textStyle.value = undefined
      return
    }
    if (textEl.value.clientHeight > (textScrollWrapper.value.clientHeight)) {
      textStyle.value = { marginTop: `-${textEl.value.clientHeight - (textScrollWrapper.value.clientHeight)}px` }
    } else {
      textStyle.value = undefined
    }
  })
})

defineExpose({
  startTyping,
})

onMounted(() => {
  startTyping()
})

onBeforeUnmount(() => {
  stopTyping()
})
</script>
